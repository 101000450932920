import { useEffect, useCallback } from 'react';
import { useBlocker } from 'react-router-dom';

export const useExitPrompt = ({
  isDirty = false,
  title = 'You have unsaved changes!',
  message = 'Are you sure you want to leave?',
  onCancel
}: {
  title?: string;
  message?: string;
  onCancel?: () => void;
  isDirty?: boolean;
}) => {
  const blocker = useBlocker(isDirty);

  const onConfirm = useCallback(() => {
    if (!isDirty) return Promise.resolve(true);

    return new Promise<boolean>((resolve) => {
      // TODO: Custom confirmation dialog wasn't working here, instead it would just automatically resolve to true and fail to show the dialog
      const confirmed = confirm(title);
      resolve(confirmed);
      if (!confirmed) onCancel?.();
    });
  }, [isDirty, onCancel, title]);

  // Block on react router events
  useEffect(() => {
    if (blocker.state === 'blocked') {
      onConfirm().then((result) => {
        if (result) blocker.proceed();
        else blocker.reset();
      });
    }
  }, [blocker, onConfirm]);

  // Block on DOM events
  useEffect(() => {
    const unloadCallback = (event: BeforeUnloadEvent) => {
      if (isDirty) {
        event.preventDefault();
        event.returnValue = message; // * required for browser compatibility

        onCancel?.();

        return message; // * required for browser compatibility
      }
    };

    // listen for page unloading
    window.addEventListener('beforeunload', unloadCallback);

    return () => {
      window.removeEventListener('beforeunload', unloadCallback);
    };
  }, [isDirty, message, onCancel]);
};
