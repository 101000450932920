import { useCallback, useState } from 'react';
import { QuestionType, QuestionTypeLabels, QuestionTypes } from './question.types';
import { Listbox } from '@headlessui/react';
import { getSelectOptionsClasses } from '../../_core/form/input/form-utils';

interface IQuestionTypeSelectProps {
  disabled?: boolean;
  forcedLabel?: string;
  onChange?: (type: QuestionType) => void;
  type?: QuestionType;
  width?: 'w-32' | 'w-16';
}

export const QuestionTypeSelect = ({
  forcedLabel,
  onChange,
  type,
  width = 'w-16',
  ...rest
}: IQuestionTypeSelectProps) => {
  const [selected, setSelected] = useState(type);

  const handleChange = useCallback(
    (v: QuestionType) => {
      if (onChange) {
        setSelected(v);
        onChange(v);
      }
    },
    [onChange]
  );

  return (
    <Listbox value={selected} onChange={handleChange} {...rest}>
      <input type="text" value={selected} readOnly className="hidden" />
      <Listbox.Button
        className={`inline-flex items-center justify-center px-3 text-sm text-gray-900 bg-gray-200 border rounded-s-md border-gray-300 h-8 ${width} ${
          rest.disabled ? '' : 'hover:bg-gray-100'
        }`}
        {...rest}
      >
        {forcedLabel ?? (!!selected && QuestionTypeLabels[selected])}
      </Listbox.Button>
      <Listbox.Options as="ul" className={`pl-0 shadow top-100 bg-white z-30 rounded absolute mt-2 ${width}`}>
        {QuestionTypes.filter((t) => t !== QuestionType.TEXTAREA).map((qt) => (
          <Listbox.Option
            as="div"
            key={qt}
            value={qt}
            className={(p) => `${getSelectOptionsClasses({ ...p, noPadding: true })} text-center`}
          >
            <span>{QuestionTypeLabels[qt]}</span>
          </Listbox.Option>
        ))}
      </Listbox.Options>
    </Listbox>
  );
};
