import { useCallback } from 'react';
import { TrashIcon } from '@heroicons/react/20/solid';
import { showError } from '../../../lib/utils';
import { useRemoveAsset } from '../../domains/assets/asset.service';
import { EditorApolloProvider } from '../_core/editor-apollo-provider';
import { IRequestTokenParams } from '../../../lib/types';
import { EditorSaveResult } from '../_core/types';
import { ConfirmButton } from '../../_core/confirm/confirm-button.component';
import { IButtonProps } from '../../_core/button/button.component';

interface IDeleteUploadButton extends IRequestTokenParams, Pick<IButtonProps, 'icon' | 'variant'> {
  assetId?: string | null;
  confirmTitle?: string;
  onDeleteStart?: () => void;
  onSave: () => Promise<EditorSaveResult>;
  requestId: string;
}

const DeleteUploadButton = ({
  assetId,
  confirmTitle,
  icon,
  onDeleteStart,
  onSave,
  requestId,
  requestToken,
  variant
}: IDeleteUploadButton) => {
  const { removeAsset } = useRemoveAsset(requestId, requestToken);

  const handleDelete = useCallback(() => {
    if (assetId)
      removeAsset({ _id: assetId })
        .then(({ data }) => {
          if (data && ('removeAsset' in data ? data?.removeAsset.success : data.removeAssetWithToken.success)) {
            onDeleteStart?.();
            onSave();
          }
        })
        .catch((err) => showError('Failed to delete asset.', err));
    else onDeleteStart?.();
  }, [assetId, onDeleteStart, onSave, removeAsset]);

  return (
    <ConfirmButton
      hideEndMargin
      icon={icon ?? <TrashIcon height={20} width={20} />}
      variant={variant ?? 'primary'}
      onConfirm={handleDelete}
      buttonTitle=""
      skipConfirm={!assetId}
      title={confirmTitle}
    />
  );
};

export const DeleteUploadButtonContainer = (props: IDeleteUploadButton) => {
  return (
    <EditorApolloProvider>
      <DeleteUploadButton {...props} />
    </EditorApolloProvider>
  );
};
