import EditorJs from '@editorjs/editorjs';
import { Button } from '../../../_core/button/button.component';
import {
  Identifiable,
  IRequest,
  IRequestOverview,
  REQUEST_NOTIFY_OPTION,
  REQUEST_STATUS,
  requestStatusLabels,
  TEMPLATE_TYPE
} from '../../../../lib/types';
import { useMemo, useState } from 'react';
import { RequestTable, IRequestTableRowValues } from './request-table.component';
import { RIDialog } from '../../../_core/dialog/dialog.component';

import { IAdminProps, IPersonalCompanyProps } from '../../../_core/core.types';
import { EditRequestContainer } from '../../../domains/request/edit/edit-request.container';
import { RequestActionButton } from '../../../domains/request/request-action-button';
import { useFetchCurrUser } from '../../../domains/users/user.service';
import { TableFilterKeys } from '../../../_core/table/table.types';
import { useTableFilterConfig, useTableSearchConfig } from '../../../_core/table/table-utils';
import { LowlightTextWithSubtitle } from '../../../_core/typography';
import { ICreateTemplateContainerProps } from '../../../domains/template/create/create-template-form-nested.container';
import { CreateTemplateContainer } from '../../../domains/template/create/create-template-form.container';
import { useLazyRequest, useSendRequest } from '../../../domains/request/request.service';
import toast from 'react-hot-toast';
import { showError } from '../../../../lib/utils';
import { useSearchParams } from 'react-router-dom';
import { SendRequestMessageContainer } from '../../../domains/conversation/send-request-message/send-request-message.container';
import { TableDate } from '../../../_core/table/table-date.component';
import { RequestStatusIcon } from '../../RequestPage/request-status-icon';

enum RequestTableType {
  ACTIVE = 'Inbox',
  WORKSPACE = 'Drafts'
}

export interface IRequestTableContainer extends IAdminProps, IPersonalCompanyProps {}

interface IRequestTableNestedContainerProps extends IRequestTableContainer {
  requests?: IRequestOverview[];
}

export const RequestTableNestedContainer: React.FC<IRequestTableNestedContainerProps> = ({ requests }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { user } = useFetchCurrUser();
  const { getRequest, loading: gettingRequest } = useLazyRequest();

  const { sendRequest, loading: sendingRequest } = useSendRequest();

  const [createRequest, setCreateRequest] = useState(false);
  const [preppingSendRequest, setPreppingSendRequest] = useState(false);
  const [requestToSend, setRequestToSend] = useState<IRequest | null>(null);
  const [template, setTemplate] = useState<ICreateTemplateContainerProps | null>(null);

  const [mode, setMode] = useState<string>(
    searchParams.has('mode')
      ? Object.values(RequestTableType).find((v) => v.toLowerCase() === searchParams.get('mode')?.toLowerCase()) ??
          RequestTableType.ACTIVE
      : RequestTableType.ACTIVE
  );
  const showingDrafts = useMemo(() => mode === RequestTableType.WORKSPACE, [mode]);

  const [searchConfig, searchMatches] = useTableSearchConfig({
    items: requests,
    keys: [
      'name',
      'company',
      'requestType',
      'company.name',
      'assignedTo.name',
      'deadline',
      'sentAt',
      'status',
      'createdBy.name'
    ]
  });
  const [filterConfig, filterFunc] = useTableFilterConfig({
    filters: [
      {
        title: TableFilterKeys.STATUS,
        placeholder: 'Filter by status',
        options: showingDrafts
          ? [REQUEST_STATUS.DRAFT, REQUEST_STATUS.ARCHIVED_DRAFT]
          : [REQUEST_STATUS.SENT, REQUEST_STATUS.CLOSED, REQUEST_STATUS.ARCHIVED],
        value: showingDrafts ? [REQUEST_STATUS.DRAFT] : [REQUEST_STATUS.SENT],
        matcher: (curr: IRequestOverview, filterValue: string[]) => {
          const isDraft = !curr.status || curr.status === REQUEST_STATUS.DRAFT;
          if (!filterValue.length) return showingDrafts ? isDraft : !isDraft;

          if (curr.deletedAt)
            return isDraft
              ? filterValue.includes(REQUEST_STATUS.ARCHIVED_DRAFT)
              : filterValue.includes(REQUEST_STATUS.ARCHIVED);

          return filterValue.includes(curr.status ?? REQUEST_STATUS.DRAFT);
        },
        labeler: (value) =>
          value in requestStatusLabels ? requestStatusLabels[value as REQUEST_STATUS].title : undefined
      }
    ]
  });

  const onCreateRequestTemplate = (variables: Identifiable) => {
    getRequest(variables)
      .then(({ data }) => {
        if (data?.requestById.blocks)
          setTemplate({
            data: { ...data.requestById, blocks: data.requestById.blocks ?? [] },
            onSubmit: () => new Promise((res) => res()),
            type: TEMPLATE_TYPE.REQUEST,
            version: data.requestById.version ?? EditorJs.version
          });
        else toast.error('Failed to get request and create template');
      })
      .catch((err) => showError('Failed to load request', err));
  };

  const onSendRequestTemplate = (variables: Identifiable) => {
    getRequest(variables)
      .then(({ data }) => {
        if (data?.requestById) setRequestToSend(data.requestById);
        else toast.error('Failed to send request');
      })
      .catch((err) => showError('Failed to load request', err));
  };

  const requestRows = useMemo(() => {
    if (!searchMatches) return [];
    const sortable = [...searchMatches];
    return sortable
      .filter(filterFunc)
      .sort((a, b) => {
        if (a.deletedAt && b.deletedAt) return a.deletedAt < b.deletedAt ? 1 : -1;
        if (a.deletedAt && !b.deletedAt) return 1;
        if (!a.deletedAt && b.deletedAt) return -1;
        return a.updatedAt < b.updatedAt ? 1 : -1;
      })
      .map((request, i) => {
        const row: IRequestTableRowValues = {
          request,
          values: [
            {
              children: <TableDate centered date={request.updatedAt} showTime />
            }
          ]
        };

        if (!showingDrafts)
          row.values.push({
            children: request.sentAt
              ? new Date(request.sentAt).toLocaleDateString('en-CA', {
                  year: '2-digit',
                  day: 'numeric',
                  month: 'numeric'
                })
              : ''
          });

        row.values.push(
          {
            children: (
              <LowlightTextWithSubtitle
                subtitle={
                  request.assignedTo?.name ?? request.assignedTo?.user.name ? request.assignedTo?.user.email : undefined
                }
              >
                {request.assignedTo?.name ?? request.assignedTo?.user.name ?? request.assignedTo?.user.email ?? ''}
              </LowlightTextWithSubtitle>
            )
          },
          { children: request.entity?.name ?? '' },
          { children: request.name },
          { children: request.requestType?.type ?? '' },
          {
            children: request.deadline ? <TableDate centered date={request.deadline} /> : ''
          }
        );

        if (!showingDrafts)
          row.values.push({
            children: (
              <div className="text-xs whitespace-nowrap">
                <span>{Math.round(request.completion.percentage * 100)}%</span>
                <span className="ml-2">
                  {request.completion.completedSections}/{request.completion.totalSections}
                </span>
              </div>
            )
          });

        row.values.push(
          {
            children: (
              <p
                className={`text-xs overflow-hidden whitespace-nowrap text-ellipsis font-bold ${
                  user?._id === request.createdBy._id ? 'text-green-600' : ''
                }`}
              >
                {request.createdBy.name}
              </p>
            )
          },
          {
            children: <RequestStatusIcon inline request={request} />
          },
          {
            children: (
              <RequestActionButton
                slim
                loading={gettingRequest || !!requestToSend || sendingRequest}
                onCreateTemplate={() => onCreateRequestTemplate({ _id: request._id })}
                onSendRequest={() => {
                  setPreppingSendRequest(true);
                  onSendRequestTemplate(request);
                }}
                onResendRequest={() => onSendRequestTemplate(request)}
                request={request}
                last={searchMatches.length > 3 && i === searchMatches.length - 1}
              />
            )
          }
        );

        return row;
      });
  }, [showingDrafts, filterFunc, searchMatches, user?._id, gettingRequest, requestToSend, sendingRequest]);

  if (!requestRows) return null;

  return (
    <>
      <RequestTable
        filterConfig={filterConfig}
        requests={requestRows}
        searchConfig={searchConfig}
        draftsMode={showingDrafts}
        modeConfig={{
          onChange: (selected) => {
            setMode(selected);
            setSearchParams({ mode: selected.toLowerCase() });
            if (selected === RequestTableType.ACTIVE)
              filterConfig.setSelectedFilters({ title: TableFilterKeys.STATUS, value: [REQUEST_STATUS.SENT] });
            else filterConfig.setSelectedFilters({ title: TableFilterKeys.STATUS, value: [REQUEST_STATUS.DRAFT] });
          },
          options: Object.values(RequestTableType),
          value: mode
        }}
      >
        <Button onClick={() => setCreateRequest(true)} text="Create Request" size="large" className="mt-1" />
      </RequestTable>
      <RIDialog open={createRequest} setOpen={setCreateRequest}>
        <EditRequestContainer />
      </RIDialog>
      {!!template && (
        <RIDialog open={!!template} setOpen={(o) => setTemplate((v) => (o ? v : null))}>
          <CreateTemplateContainer close={() => setTemplate(null)} {...template} />
        </RIDialog>
      )}
      {!!requestToSend && (
        <RIDialog open={!!requestToSend} setOpen={(o) => setRequestToSend((r) => (o ? r : null))}>
          <SendRequestMessageContainer
            close={() => {
              setPreppingSendRequest(false);
              setRequestToSend(null);
            }}
            request={requestToSend}
            forceSendAll={preppingSendRequest}
            onSubmit={
              preppingSendRequest
                ? ({ message, notify = [REQUEST_NOTIFY_OPTION.ALL], replyTo, subject }) =>
                    sendRequest({ _id: requestToSend._id, customMessage: message, replyTo, notify, subject })
                : undefined
            }
          />
        </RIDialog>
      )}
    </>
  );
};
