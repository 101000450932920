import { PopoverItemParams } from '@editorjs/editorjs';
import { BlockToolConstructorOptions, BlockToolData, TunesMenuConfigDefaultItem } from '@editorjs/editorjs/types/tools';
import { CreateTemplateBlockTool, ICreateTemplateBlockConfig } from './create-template-block';

export interface IRequiredBlockData extends BlockToolData {
  completed?: boolean;
  filled?: boolean;
  required?: boolean;
}

export const REQUIRED_TUNE = 'required';

export class RequiredBlockTool<
  T extends IRequiredBlockData,
  U extends ICreateTemplateBlockConfig
> extends CreateTemplateBlockTool<T, U> {
  protected indicatorId: string;

  private completedId: string;
  private completedWrapperId: string;
  private settingsTuneIndex: number;

  private requiredTune: TunesMenuConfigDefaultItem = {
    name: REQUIRED_TUNE,
    label: 'Required',
    icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z" /></svg>',
    onActivate: (p: PopoverItemParams) => this.toggleTune(p),
    toggle: true
  };

  constructor(props: BlockToolConstructorOptions<T>) {
    super(props);
    const { data } = props;

    this.data = {
      ...data,
      completed: data.completed ?? false,
      filled: data.filled ?? false,
      required: data.required ?? false
    };

    this.settingsTuneIndex = this.settings.length;
    this.settings.push(this.requiredTune);

    this.completedId = 'completed-' + this.uid;
    this.completedWrapperId = this.completedId + '-wrapper';
    this.indicatorId = 'indicator-' + this.uid;
  }

  protected renderCompletedCheckbox(): HTMLDivElement | null {
    // TODO: Determine new completex UX
    // if (this.data.required && !this.api.readOnly.isEnabled) {
    //   const completedWrapper = document.createElement('div');
    //   completedWrapper.id = this.completedWrapperId;
    //   completedWrapper.classList.add('flex', 'gap-2', 'items-center');

    //   const checkbox = document.createElement('input');
    //   checkbox.classList.add('cursor-pointer');
    //   checkbox.setAttribute('type', 'checkbox');
    //   checkbox.id = this.completedId;
    //   checkbox.checked = this.data.completed;

    //   // Toggle indicator color on checkbox state change
    //   checkbox.onclick = ({ target }) => this.toggleRequiredIndicator(target as HTMLInputElement);

    //   const label = document.createElement('label');
    //   label.classList.add('cursor-pointer');
    //   label.setAttribute('for', this.completedId);
    //   label.textContent = 'Completed';

    //   completedWrapper.appendChild(checkbox);
    //   completedWrapper.appendChild(label);
    //   return completedWrapper;
    // }

    return null;
  }

  protected renderRequiredIndicator(): HTMLDivElement | null {
    if (this.data.required) {
      const indicator = document.createElement('div');
      indicator.id = this.indicatorId;
      indicator.classList.add(
        'rounded-full',
        'min-h-4',
        'min-w-4',
        'my-2',
        // Mark field as okay if completed or filled, completed overrides the filled state
        this.data.completed || this.data.filled ? 'bg-success' : 'bg-error'
      );

      const indicatorContainer = document.createElement('div');
      indicatorContainer.classList.add('ml-auto');
      indicatorContainer.append(indicator);

      return indicatorContainer;
    }

    return null;
  }

  protected toggleRequiredIndicator({ checked }: { checked: boolean }) {
    const indicator = document.querySelector('#' + this.indicatorId) as HTMLDivElement | null;
    if (indicator) {
      indicator.classList.remove('bg-error', 'bg-success');
      indicator.classList.add(this.data.filled || checked ? 'bg-success' : 'bg-error');
    }
  }

  async save(blockContent: HTMLElement): Promise<T> {
    const checkbox = blockContent.querySelector('#' + this.completedId) as HTMLInputElement | undefined;
    return { ...this.data, completed: checkbox?.checked ?? this.data.completed };
  }

  toggleRequiredView() {
    if (this.data.required) {
      const components: HTMLElement[] = [];

      const indicator = this.renderRequiredIndicator();
      if (indicator) components.push(indicator);

      const completedCheckbox = this.renderCompletedCheckbox();
      if (completedCheckbox) components.push(completedCheckbox);

      document.getElementById(this.wrapperId)?.append(...components);
    } else {
      document.getElementById(this.indicatorId)?.remove();
      document.getElementById(this.completedWrapperId)?.remove();
    }
  }

  // Block Settings
  private acceptRequiredTuneView() {
    this.settings.forEach((params) => {
      if (params.name === REQUIRED_TUNE) this.toggleRequiredView();
    });
  }

  renderSettings() {
    if (this.data.required) this.settings[this.settingsTuneIndex].isActive = true;
    return this.settings;
  }

  private toggleTune(params: PopoverItemParams) {
    if ('name' in params) {
      if (params.name === REQUIRED_TUNE) this.data.required = !this.data.required;
      this.acceptRequiredTuneView();
    }
  }
}
