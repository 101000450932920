import { useEntities } from '../../../domains/entities/entity.service';
import { Loader } from '../../../_core/loader.component';
import { EntityTableNestedContainer } from './entity-table.nested.container';
import { IAdminProps, IPersonalCompanyProps } from '../../../_core/core.types';
import { ITableModeConfig } from '../../../_core/table/table-with-actions.component';

interface IEntityTableContainer extends IAdminProps, IPersonalCompanyProps {
  modeConfig?: ITableModeConfig;
}

export const EntityTableContainer: React.FC<IEntityTableContainer> = (props) => {
  const { entities } = useEntities();

  if (!entities) return <Loader />;
  return <EntityTableNestedContainer entities={entities} {...props} />;
};
