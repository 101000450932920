import { Edge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/dist/types/types';
import { IAsset } from '../../../lib/types';
import { ILabelBlockData } from '../_core/label-block';

export type UploadMode = 'provide' | 'upload' | 'upload-list';

interface CommonUploadItemData {
  fileName: string;
  fileId?: string | null;
  label?: string;
  notes?: string;
  clientAdded?: boolean;
}

export interface UploadItemData extends CommonUploadItemData {
  fileContainerId: string;
}

export interface LegacyUploadItemData extends CommonUploadItemData {
  fileContainerId?: string;
}

export interface UploadData extends ILabelBlockData {
  uploads: UploadItemData[];
}

export interface LegacyUploadData extends ILabelBlockData {
  uploads: LegacyUploadItemData[];
}

export interface UploadFilesContainerHandle {
  addFile: (_: IAsset) => void;
  updateNestedDepth: (_: number) => void;
}

export interface UploadDragItem {
  index: number;
  id: string;
  type: string;
}

export type DragState =
  | {
      type: 'idle';
    }
  | {
      type: 'preview';
      container: HTMLElement;
    }
  | {
      type: 'is-dragging';
    }
  | {
      type: 'is-dragging-over';
      closestEdge: Edge | null;
    };

export const IDLE: DragState = { type: 'idle' };

export type UploadFileType = 'docProvide' | 'upload';
