export default function TermsAndConditionsPage() {
  return (
    <div className="flex min-h-full flex-1 flex-col justify-center">
      <div className="mx-auto max-w-[90%] w-full p-4 flex flex-1 flex-col m-6 bg-white rounded-2xl">
        <h1>Terms and Conditions</h1>
        <h3>Put policy here...</h3>
      </div>
    </div>
  );
}
