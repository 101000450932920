import { Outlet } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { useEffect } from 'react';

export const ToastProvider = () => {
  useEffect(() => {
    const metaBlock = document.getElementById('clientBuildVersion') as HTMLMetaElement;
    console.log({ clientVersion: metaBlock?.getAttribute('build-version') });
  }, []);

  return (
    <>
      <Outlet />
      <Toaster position="bottom-right" />
    </>
  );
};
