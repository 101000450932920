import { Loader } from '../../_core/loader.component';
import {
  ICompany,
  IDepartment,
  IInviteOverview,
  ILocation,
  IMemberOverview,
  IRequestType,
  IUserOverview,
  ROLE,
  ROLE_LABELS
} from '../../../lib/types';
import { Button } from '../../_core/button/button.component';
import { ITabProps, Tabs } from '../../_core/tabs.component';
import { activeStatus } from '../../../utils/wording.helpers';
import { IUpdateCompanyProps } from '../../domains/companies/company.types';
import { StaffTableContainer } from './staff-table/staff-table.container';
import { DepartmentTableContainer } from './department-table/department-table.container';
import { LocationTableContainer } from './location-table/location-table.container';
import { RequestTypeTable } from './request-type-table/request-type-table.component';
import { ConfirmButton } from '../../_core/confirm/confirm-button.component';
import { IAdminProps, IPersonalCompanyProps } from '../../_core/core.types';
import { RIDialog } from '../../_core/dialog/dialog.component';
import { useMemo, useState } from 'react';
import { TransferOwnershipContainer } from '../../domains/companies/transfer-ownership/transfer-ownership-form.container';
import { sortRoles } from '../../../utils/role.helpers';
import { ProfileDetailsBox } from '../../_core/details/profile-details-box.component';
import { UpdateCompanyContainer } from '../../domains/companies/update/update-company-form.container';
import { FirmProfilePageTableMode } from './firm-profile-page.types';
import { useSearchParams } from 'react-router-dom';

interface IFirmProfilePageProps extends IPersonalCompanyProps, IAdminProps {
  company?: ICompany;
  completeOnboarding?: boolean;
  currMember?: IMemberOverview;
  defaultTab?: FirmProfilePageTableMode | null;
  departments: IDepartment[];
  invites?: IInviteOverview[];
  locations: ILocation[];
  members?: IMemberOverview[];
  inactiveCompanyRequestTypes: IRequestType[];
  selectedRequestTypes: IRequestType[];
  updateCompany: (_: IUpdateCompanyProps) => void;
  updating: boolean;
  user: IUserOverview;

  removeSelf: () => void;
  removingSelf: boolean;
}

export const FirmProfilePage: React.FC<IFirmProfilePageProps> = ({
  company,
  // completeOnboarding,
  currMember,
  defaultTab,
  departments,
  invites,
  isAdmin,
  isPersonalCompany,
  locations,
  members,
  inactiveCompanyRequestTypes,
  removeSelf,
  removingSelf,
  selectedRequestTypes,
  updateCompany,
  updating,
  user
}) => {
  const [_, setSearchParams] = useSearchParams();
  const setTab = (t: number) => setSearchParams({ table: Object.values(FirmProfilePageTableMode)[t].toLowerCase() });

  const [editing, setEditing] = useState(false);
  const [showTransfer, setShowTransfer] = useState(false);

  const memberRole = useMemo(
    () => (currMember?.roles.length ? [...currMember.roles].sort(sortRoles)[0] : ROLE.COMPANY_STAFF),
    [currMember?.roles]
  );

  const otherMembers = useMemo(() => members?.filter(({ _id }) => _id !== currMember?._id), [currMember, members]);

  const isOwner = memberRole === ROLE.COMPANY_OWNER;

  const actionItems = useMemo(() => {
    const newItems: JSX.Element[] = [];

    if (isOwner) {
      newItems.push(
        <Button size="xl" onClick={() => setEditing(true)}>
          Edit Organization
        </Button>
      );

      if (otherMembers?.length)
        newItems.push(
          <Button size="xl" onClick={() => setShowTransfer(true)}>
            Transfer Ownership
          </Button>
        );
    } else if (!isPersonalCompany)
      newItems.push(
        <ConfirmButton
          size="xl"
          onConfirm={removeSelf}
          loading={removingSelf}
          buttonTitle="Leave Organization"
          title="Are you sure you want to leave the organization?"
        />
      );

    if (company && (isOwner || company.deletedAt))
      newItems.push(
        <Button
          size="xl"
          text={`${company.deletedAt ? 'Una' : 'A'}rchive Organization`}
          onClick={() => updateCompany({ active: !!company.deletedAt })}
          loading={updating}
        />
      );

    return newItems;
  }, [company, isOwner, isPersonalCompany, otherMembers?.length, removeSelf, removingSelf, updateCompany, updating]);

  const requestTypesTab: ITabProps = {
    title: 'Request Types',
    content: (
      <RequestTypeTable isAdmin={isAdmin} requestTypes={[...selectedRequestTypes, ...inactiveCompanyRequestTypes]} />
    )
  };

  if (!company)
    return (
      <div className="mx-auto">
        <Loader />
      </div>
    );

  return (
    <>
      <ProfileDetailsBox
        actionItems={actionItems}
        detailItems={[
          { label: 'Organization', value: company.name },
          { label: 'Status', value: activeStatus(!company.deletedAt) },
          { label: 'Your Role', value: memberRole ? ROLE_LABELS[memberRole] : undefined }
        ]}
        title="Organization Profile"
      />
      <div className="mt-4 flex flex-1 w-full pb-2">
        <>
          {isPersonalCompany ? (
            <Tabs tabs={[requestTypesTab]} />
          ) : (
            <Tabs
              afterChange={setTab}
              defaultTab={defaultTab ? Object.keys(FirmProfilePageTableMode).indexOf(defaultTab) : 0}
              tabs={[
                {
                  title: 'Team Members',
                  content:
                    invites && members ? (
                      <StaffTableContainer
                        isAdmin={isAdmin}
                        invites={invites}
                        members={members}
                        currUserId={user._id}
                      />
                    ) : (
                      <Loader />
                    )
                },
                {
                  title: 'Departments',
                  content: <DepartmentTableContainer isAdmin={isAdmin} departments={departments} />
                },
                { title: 'Locations', content: <LocationTableContainer isAdmin={isAdmin} locations={locations} /> },
                requestTypesTab
              ]}
            />
          )}
        </>
      </div>
      <RIDialog open={editing} setOpen={setEditing}>
        <UpdateCompanyContainer company={company} close={() => setEditing(false)} />
      </RIDialog>
      <RIDialog open={showTransfer} setOpen={setShowTransfer}>
        <TransferOwnershipContainer
          currMember={currMember}
          members={otherMembers}
          close={() => setShowTransfer(false)}
        />
      </RIDialog>
    </>
  );
};
