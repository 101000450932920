import _ from 'lodash';
import {
  LOCAL_TITLE_CLASS,
  LOCAL_TITLE_SUFFIX
} from '../../_editor/_core/local-title-block/local-title-block.component';
import { UploadItemData } from '../../_editor/upload/upload.types';

const getLocalTitleParents = (acc: HTMLElement[], fileElement?: HTMLElement | null) => {
  const parent = fileElement?.querySelector('.' + LOCAL_TITLE_CLASS) as HTMLElement | undefined;
  if (parent) {
    // Add grandparents local titles to the accumulator first
    getLocalTitleParents(acc, fileElement?.parentElement?.closest('.' + 'ce-block') as HTMLElement | null);
    acc.push(parent);
  }

  return acc;
};

export const getDownloadFileName = ({
  itemData,
  getLatestFileLabel,
  skipFileElementCheck,
  useOriginalFileName
}: {
  itemData: UploadItemData;
  getLatestFileLabel?: (_: string) => string | null;
  skipFileElementCheck?: boolean;
  useOriginalFileName?: boolean;
}) => {
  let fileName = '';

  if (!skipFileElementCheck) {
    // Get prefix of all nested local title blocks values
    const fileContainer = document.getElementById('file-' + itemData.fileContainerId);
    const fileElement = fileContainer?.closest('.' + 'ce-block');
    if (fileElement) {
      const parentTitles = [
        ...getLocalTitleParents([], fileElement as HTMLElement),
        fileContainer?.querySelector('.' + LOCAL_TITLE_CLASS) as HTMLElement
      ].map((e) => (e.textContent?.endsWith(LOCAL_TITLE_SUFFIX) ? e.textContent?.split(LOCAL_TITLE_SUFFIX)?.[0] : ''));

      // Collect all nested local titles and prepend to file name
      const parentTitle = parentTitles.join('');
      if (parentTitle) {
        // Add period after section local title if file is nested further than that. This should apply in all cases, but we add a default behavior just in case.
        if (parentTitle.length > 1) fileName += `${parentTitle.charAt(0)}.${parentTitle.slice(1)} - `;
        else fileName += `${parentTitle} - `;
      }
    }
  }

  // Get correct filename
  if (useOriginalFileName) {
    fileName += itemData.fileName;
  } else {
    fileName += getLatestFileLabel
      ? getLatestFileLabel(itemData.fileContainerId) || itemData.fileName
      : itemData.label || itemData.fileName;

    const fileExt = itemData.fileName.split('.').pop();
    if (fileExt && fileName) {
      // Only add file extension if it isn't already present at the end of the file, prevents double extensions when original fileName is used instead of file label
      const fileExtSuffix = '.' + fileExt;
      if (!fileName.endsWith(fileExtSuffix)) fileName += fileExtSuffix;
    }
  }

  // Ensure file name is unescaped
  return _.unescape(fileName);
};
