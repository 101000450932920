import { useFormFieldMeta } from '../form-field-meta';
import { IFormInputProps } from './form-input.component';
import { IMultiSelectInputProps, IMultiSelectOption, MultiSelectInput } from '../../input/multiselect-input.component';

export const FormMultiSelectInput = ({ name, ...rest }: IMultiSelectInputProps & IFormInputProps) => {
  const {
    value: formValue,
    helpers: { setValue: setFormValue }
  } = useFormFieldMeta<IMultiSelectOption[]>(name);

  return <MultiSelectInput {...rest} setValue={setFormValue} value={formValue} />;
};
