import { useAuth0 } from '@auth0/auth0-react';
import { Navigate, Outlet, useParams } from 'react-router-dom';
import { ROUTE_PATHS } from '../../_routes';
import toast from 'react-hot-toast';
import { useEffect } from 'react';

import LogRocket from 'logrocket';
import { useLogout } from '../domains/auth/auth.service';
import { useFetchCurrUser } from '../domains/users/user.service';

interface IAuthenticatedLayoutWrapper {
  redirectMessage?: string;
  redirectMessageType?: 'error' | 'info';
  redirectPath?: ROUTE_PATHS;
}

export const AuthenticatedLayoutWrapper = ({
  redirectMessage = 'You must login to continue.',
  redirectMessageType = 'error',
  redirectPath = ROUTE_PATHS.LANDING
}: IAuthenticatedLayoutWrapper) => {
  const params = useParams();
  const { isAuthenticated } = useAuth0();
  const { user } = useFetchCurrUser();
  const { handleLogout } = useLogout();

  useEffect(() => {
    if (!isAuthenticated) {
      handleLogout({
        afterExecution: () => {
          if (redirectMessageType === 'error') toast.error(redirectMessage, { duration: 5000, id: 'unauthenticated' });
          else toast(redirectMessage, { duration: 5000, id: 'unauthenticated' });
        }
      });
    } else if (user && import.meta.env.VITE_LOG_ROCKET_APP_ID) {
      LogRocket.identify(user._id, { name: user.name ?? '', email: user.email });
      LogRocket.startNewSession();
      LogRocket.getSessionURL((url) => console.log('Identify log rocket user', { user, url }));
    }
  }, [handleLogout, isAuthenticated, redirectMessage, redirectMessageType, user]);

  if (!isAuthenticated) return <Navigate to={redirectPath} state={{ params }} />;
  return <Outlet />;
};
