import { IRenderOpenToggleParams, renderOpenToggle } from './editor.utils';

// Render headers for branch and repeat section templates
export const renderSectionTemplateHeader = ({
  elementWrapperClass,
  forceShowHeader,
  idPrefix,
  onOpenToggle,
  open,
  readOnly,
  sectionElement,
  sectionIndex,
  sectionTitleText
}: {
  elementWrapperClass?: string;
  forceShowHeader?: boolean;
  idPrefix: string;
  onOpenToggle: (_: { index: number; renderParams: IRenderOpenToggleParams }) => void;
  open: boolean;
  readOnly?: boolean;
  sectionElement: HTMLDivElement | null;
  sectionIndex: number;
  sectionTitleText?: string;
}) => {
  const ids = getSectionElementIds(idPrefix, sectionIndex);

  // Add title to section wrapper
  const sectionElementWrapper = document.createElement('div');
  if (elementWrapperClass) sectionElementWrapper.classList.add(elementWrapperClass);

  if (!readOnly || forceShowHeader) {
    const sectionTitleWrapper = document.createElement('div');
    sectionTitleWrapper.id = ids.titleWrapper;
    sectionTitleWrapper.classList.add('flex', 'gap-2', 'items-center');

    if (sectionTitleText) {
      const sectionTitle = document.createElement('div');
      sectionTitle.textContent = sectionTitleText;
      sectionTitleWrapper.appendChild(sectionTitle);
    }

    const spacer = document.createElement('div');
    spacer.classList.add('flex-1', 'border-b', readOnly ? 'border-gray-400' : 'border-black', 'self-center');
    sectionTitleWrapper.appendChild(spacer);

    if (!readOnly) {
      // Add open toggle to section template titles
      const renderParams: IRenderOpenToggleParams = {
        additionalClass: '-ml-0.5',
        open,
        openToggleId: ids.openToggle,
        parent: sectionTitleWrapper
      };
      renderParams.onOpenToggle = () => onOpenToggle({ index: sectionIndex, renderParams });
      renderOpenToggle(renderParams);
    }

    sectionElementWrapper.appendChild(sectionTitleWrapper);
  }

  // Add section content to wrapper
  if (sectionElement) {
    sectionElement.id = ids.element;
    sectionElement.classList.toggle('hidden', !open);
    sectionElementWrapper.appendChild(sectionElement);
  } else sectionElementWrapper.classList.add('hidden');

  return sectionElementWrapper;
};

export const getSectionElementIds = (idPrefix: string, sectionIndex: number) => {
  const prefix = idPrefix + sectionIndex;
  return {
    element: prefix + '-section-element',
    openToggle: prefix + '-open-toggle',
    titleWrapper: prefix + '-section-title-wrapper'
  };
};
