import { Formik, FormikErrors, FormikProps } from 'formik';
import { FormButton } from '../../../_core/form/button/form-button.component';
import { IRIFormProps } from '../../../_core/form/form.types';
import { IRequest, ITemplateBaseDetails } from '../../../../lib/types';
import { IPersonalCompanyProps } from '../../../_core/core.types';
import { ICreateRequestProps } from '../request.types';
import { EditRequestFormFields, IEditRequestFormFieldProps } from './edit-request-form-fields';
import { IMultiSelectOption } from '../../../_core/input/multiselect-input.component';

interface IEditRequestFormProps
  extends IRIFormProps<ICreateRequestProps>,
    IPersonalCompanyProps,
    IEditRequestFormFieldProps {
  currUserId: string;
  request?: IRequest;
  template?: ITemplateBaseDetails;
}

export const EditRequestForm = ({ onSubmit, request, template, currUserId, ...rest }: IEditRequestFormProps) => {
  const validateForm = (values: ICreateRequestProps) => {
    const errors: FormikErrors<ICreateRequestProps> = {};
    if (!values.name) errors.name = 'Required';
    return errors;
  };

  return (
    <Formik
      initialValues={
        request
          ? ({
              name: request.name,
              assignedTo: request.assignedTo
                ? [
                    {
                      label: request.assignedTo.name ?? request.assignedTo.user.name ?? request.assignedTo.user.email,
                      secondaryLabel: request.assignedTo.user.email,
                      value: request.assignedTo._id
                    }
                  ]
                : [],
              ccClients:
                request.clientEditorIds?.map(({ _id: value, name, user }) => ({
                  label: name ?? user.name ?? user.email,
                  secondaryLabel: user.email,
                  value
                })) ?? [],
              deadline: request.deadline,
              entity: request.entity ? [{ label: request.entity.name, value: request.entity._id }] : [],
              notes: request.notes,
              requestType: request.requestType
                ? [{ label: request.requestType.type, value: request.requestType._id }]
                : [],
              staff:
                request.staffEditorIds
                  ?.filter(({ user }) => user._id !== request.createdBy._id)
                  .map(({ _id: value, user }) => ({
                    label: user.name ?? user.email,
                    value,
                    secondaryLabel: user.email
                  })) ?? []
            } as ICreateRequestProps)
          : ({
              name: '',
              assignedTo: [],
              ccClients: [],
              entity: [],
              requestType: [],
              staff: [],
              templateId: template
                ? ([
                    {
                      value: template._id,
                      label: template.title,
                      secondaryLabel: template.description
                    }
                  ] as IMultiSelectOption[])
                : []
            } as ICreateRequestProps)
      }
      onSubmit={onSubmit}
      validate={validateForm}
      validateOnBlur={false}
    >
      {(props: FormikProps<ICreateRequestProps>) => (
        <EditRequestFormFields
          {...props}
          {...rest}
          createdById={request?.createdBy._id ?? currUserId}
          hasBeenCreated={!!request}
        >
          <FormButton loading={props.isSubmitting} text={request ? 'Update' : 'Create'} />
        </EditRequestFormFields>
      )}
    </Formik>
  );
};
