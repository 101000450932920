import { useCallback } from 'react';
import { ArrowDownTrayIcon } from '@heroicons/react/20/solid';
import { Button } from '../../_core/button/button.component';
import { useDownloadAsset } from './asset.service';
import { EditorApolloProvider } from '../../_editor/_core/editor-apollo-provider';
import { UploadItemData } from '../../_editor/upload/upload.types';
import { IRequestTokenParams } from '../../../lib/types';
import { getDownloadFileName } from './download-utils';

interface IDownloadAssetButtonProps extends UploadItemData, IRequestTokenParams {
  getLatestFileLabel: (_: string) => string | null;
  forceDownloadWithLabel?: boolean;
}

const DownloadAssetButton = ({
  forceDownloadWithLabel,
  getLatestFileLabel,
  requestToken,
  ...itemData
}: IDownloadAssetButtonProps) => {
  const { downloadAsset } = useDownloadAsset(itemData, requestToken);

  const handleDownload = useCallback(() => {
    const fileName = getDownloadFileName({ getLatestFileLabel, itemData });
    downloadAsset(fileName);
  }, [downloadAsset, getLatestFileLabel, itemData]);

  return (
    <Button
      hideEndMargin
      icon={<ArrowDownTrayIcon height={20} width={20} />}
      variant="secondary"
      onClick={handleDownload}
    />
  );
};

export const DownloadAssetButtonContainer = (props: IDownloadAssetButtonProps) => {
  return (
    <EditorApolloProvider>
      <DownloadAssetButton {...props} />
    </EditorApolloProvider>
  );
};
