import { forwardRef, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { flushSync } from 'react-dom';
import { monitorForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { extractClosestEdge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import { reorderWithEdge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/util/reorder-with-edge';
import { triggerPostMoveFlash } from '@atlaskit/pragmatic-drag-and-drop-flourish/trigger-post-move-flash';
import { PlusIcon } from '@heroicons/react/20/solid';
import _, { uniqueId } from 'lodash';

import { UploadFilesContainerHandle, UploadItemData, UploadMode } from './upload.types';
import { Button } from '../../_core/button/button.component';
import { EditorSaveResult } from '../_core/types';
import { UploadFile } from './upload-file';
import { logError } from '../../../lib/utils';
import { IAsset } from '../../../lib/types';
import { UploadInput } from './upload-input';

export const UploadFilesContainer = forwardRef<
  UploadFilesContainerHandle,
  {
    uploads: UploadItemData[];
    disabled?: boolean;
    inlineUploadId: string;
    mode: UploadMode;
    onSave: () => Promise<EditorSaveResult>;
    onUpdate: (_: UploadItemData[]) => void;
    readOnly: boolean;
    requestId: string;
    requestToken?: string;
    uid: string;
  }
>(({ inlineUploadId, onUpdate, uploads, ...rest }, ref) => {
  const quickCreateRef = useRef<HTMLInputElement | null>(null);

  const [data, setData] = useState(uploads);

  const createFileContainerId = () => new Date().getTime() + ' ' + uniqueId();

  const handleFileUpdate = (a: IAsset, originalFileData?: UploadItemData): UploadItemData => ({
    ...originalFileData,
    fileContainerId: originalFileData?.fileContainerId ?? createFileContainerId(),
    fileName: a.name,
    fileId: a._id,
    label: originalFileData?.label ?? a.name,

    // It's considered added by the client if attached during a session with a request token, and file is entirely new
    clientAdded: originalFileData?.clientAdded ?? (!!rest.requestToken && !originalFileData)
  });

  const onAddFile = (asset: IAsset) => {
    const newFile = handleFileUpdate(asset);
    setData((prev) => {
      const newData = [...prev, newFile];
      onUpdate(newData);
      return newData;
    });
  };

  // Create callback to alow updating nested depth for local title from parent updated callback
  const [nestedDepth, setNestedDepth] = useState(0);
  useImperativeHandle(ref, () => ({ addFile: onAddFile, updateNestedDepth: (depth) => setNestedDepth(depth + 1) }));

  const onRemove = (fileContainerId: string) => {
    setData((prev) => {
      const newData = [...prev.filter((d) => d.fileContainerId !== fileContainerId)];
      onUpdate(newData);
      return newData;
    });
  };

  const handleUpdate = ({ fileContainerId, ...updateData }: UploadItemData) => {
    setData((prev) => {
      const fileIndex = data.findIndex((u) => u.fileContainerId === fileContainerId);
      if (fileIndex !== -1) {
        const newFile = { ...data[fileIndex], ...updateData };

        const newData = [...data];
        newData[fileIndex] = newFile;

        onUpdate(newData);
        return newData;
      } else logError('Failed to update upload');

      return prev;
    });
  };

  const filesContainerHeaderId = 'files-container-header-' + rest.uid;

  const quickCreateButton = useMemo(() => {
    if (rest.mode !== 'provide' || !rest.readOnly) {
      const shouldUploadFilesImmediately = rest.readOnly || rest.mode !== 'upload-list';

      const button = (
        <div
          id={inlineUploadId}
          className={`flex flex-col ${rest.mode === 'upload' && !uploads.length ? 'hidden' : ''} ${
            !data.length && 'mt-2'
          }`}
        >
          <div className="ml-8">
            <Button
              icon={
                <PlusIcon
                  height={20}
                  width={20}
                  className="my-auto rounded-md bg-green-400 hover:bg-green-300 text-white mr-auto"
                />
              }
              className="ce-toolbar__button !p-0 !text-green-400 hover:bg-opacity-60"
              onClick={() => {
                if (shouldUploadFilesImmediately) quickCreateRef.current?.click();
                else
                  setData((prev) => {
                    const newData: UploadItemData[] = [
                      ...prev,
                      { fileContainerId: createFileContainerId(), fileName: '', clientAdded: !!rest.requestToken }
                    ];
                    onUpdate(newData);
                    return newData;
                  });
              }}
              variant="custom"
            />
          </div>
        </div>
      );

      if (shouldUploadFilesImmediately)
        return (
          <UploadInput {...rest} multiple onUploaded={onAddFile} uploadRef={quickCreateRef}>
            {button}
          </UploadInput>
        );
      return button;
    }
  }, [inlineUploadId, rest, uploads.length]);

  monitorForElements({
    canMonitor: ({ source }) => 'fileContainerId' in source.data,
    onDrop({ location, source }) {
      const target = location.current.dropTargets[0];
      if (!target) return;

      const sourceData = source.data;
      const targetData = target.data;

      if (!('fileContainerId' in source.data) || !('fileContainerId' in target.data)) return;

      // Using `flushSync` so we can query the DOM straight after this line
      flushSync(() => {
        setData((prev) => {
          const indexOfSource = prev.findIndex((u) => u.fileContainerId === sourceData.fileContainerId);
          const indexOfTarget = prev.findIndex((u) => u.fileContainerId === targetData.fileContainerId);
          if (indexOfTarget < 0 || indexOfSource < 0) return prev;

          const newData = reorderWithEdge({
            list: prev,
            startIndex: indexOfSource,
            indexOfTarget,
            closestEdgeOfTarget: extractClosestEdge(targetData),
            axis: 'vertical'
          });
          onUpdate(newData);

          // Being simple and just querying for the item after the drop.
          // We could use react context to register the element in a lookup,
          // and then we could retrieve that element after the drop and use
          // `triggerPostMoveFlash`. But this gets the job done.
          const element = document.querySelector(`[data-handler-id="${sourceData.fileContainerId}"]`);
          if (element instanceof HTMLElement) triggerPostMoveFlash(element);

          return newData;
        });
      });
    }
  });

  return (
    <>
      {!!data.length && (
        <>
          {(rest.mode !== 'provide' || !rest.readOnly) && (
            <div id={filesContainerHeaderId} className="flex gap-4 ml-8">
              <div className="flex w-8/12 gap-4">
                <span className="w-full text-center text-xs font-bold">Document name</span>
                <span className="w-full text-center text-xs font-bold">{rest.mode !== 'provide' && 'Notes'}</span>
              </div>
            </div>
          )}
          {data.map((u, uIndex) => (
            <UploadFile
              key={u.fileContainerId}
              {...rest}
              data={u}
              index={uIndex}
              onFileUpdate={handleFileUpdate}
              onRemove={() => onRemove(u.fileContainerId)}
              onUpdate={handleUpdate}
              nestedDepth={nestedDepth}
            />
          ))}
        </>
      )}
      {quickCreateButton}
    </>
  );
});
