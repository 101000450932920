import React, { memo, useCallback, useMemo } from 'react';
import EditorJs, { API, BlockMutationEvent, OutputData } from '@editorjs/editorjs';
import DragDrop from 'editorjs-drag-drop';
import { ICreateTemplateBlockConfig, ICreateBlockTemplateParams } from '../../_editor/_core/create-template-block';

import { FormEditorType, IEditorRefProps } from './form-editor.types';
import { useFocusedBlockTracking } from '../../_editor/_core/utils/editor.utils';

import './form-editor.css';
import '../../_editor/section/section.css';
import { getEditorTools } from './form-editor.utils';
import { Button } from '../../_core/button/button.component';
import { PlusIcon } from '@heroicons/react/20/solid';
import { usePrev } from '../../../lib/utils';
import _ from 'lodash';
import { ISectionData } from '../../_editor/section/section.types';
import { EditorSaveResult } from '../../_editor/_core/types';
import { BlockType } from '../../_editor/_core/editor.const';

interface IEditor extends IEditorRefProps {
  canRefreshData?: boolean;
  setCanRefreshData?: (_: boolean) => void;
  data: OutputData;
  onChange?: (api: API, event: BlockMutationEvent | BlockMutationEvent[]) => void;
  onCreateTemplate?: (_: ICreateBlockTemplateParams) => void;
  onReady?: () => void;
  onSave: () => Promise<EditorSaveResult>;
  openFirstSection?: boolean; // Open first uncompleted section on first load
  disabled: boolean;
  nested?: boolean;
  previewing?: boolean;
  quickCreateTitle?: string;
  readOnly: boolean;
  requestId: string;
  requestSent?: boolean;
  type?: FormEditorType;
  token?: string;
}

const Editor: React.FC<IEditor> = ({
  canRefreshData,
  setCanRefreshData,
  data,
  editorRef,
  onChange,
  onCreateTemplate,
  onReady,
  onSave,
  openFirstSection,
  editorblock,
  disabled,
  nested,
  previewing,
  quickCreateTitle,
  readOnly,
  requestId,
  requestSent,
  type,
  token
}) => {
  // TODO: Why is this re-rendering despite memoized and containing component having no state or non-callbacks? need to troubleshoot with why-did-you-render
  // console.log('Why re-render?');

  const prevData = usePrev(data);
  const prevDisabled = usePrev(disabled);
  const prevReadOnly = usePrev(readOnly);

  useFocusedBlockTracking({ editorblock, editorRef });

  const blocksWithOpenSections = useMemo(() => {
    // NOTE: Determine which section should be opened first for client view based on first incomplete section
    let markedOpen = false;

    const newBlocks = data.blocks?.map(({ data: blockData, type, ...block }, blockIndex) => {
      const updatedData = { ...blockData };
      if (type === 'section') {
        updatedData.blockIndex = blockIndex;
        updatedData.totalBlocks = data.blocks?.length ?? 0;
        updatedData.requestSent = requestSent;
      }

      if (type !== 'section' || markedOpen) return { data: updatedData, type, ...block };

      const forceOpen = !openFirstSection || !(updatedData as ISectionData).completed;
      if (openFirstSection && forceOpen) markedOpen = true;
      return { ...block, type, data: { ...updatedData, forceOpen } };
    });
    return newBlocks;
  }, [data.blocks, openFirstSection, requestSent]);

  // Quickly insert new blocks into the document
  const onQuickCreate = useCallback(
    ({
      count = 1,
      currEditor = editorRef.current,
      useCurrentBlock
    }: {
      count: number;
      currEditor?: EditorJs;
      useCurrentBlock?: boolean;
    }) => {
      if (currEditor) {
        for (let i = 0; i < count; i++) {
          const blockCount = currEditor.blocks.getBlocksCount();
          const targetBlockIndex = useCurrentBlock ? currEditor.blocks.getCurrentBlockIndex() : blockCount - 1;
          const targetBlock = currEditor.blocks.getBlockByIndex(targetBlockIndex);

          // If there are no blocks or last block is empty, then prompt user with block menu
          if (blockCount <= 1 && targetBlock?.isEmpty) {
            currEditor.focus(true);
            currEditor.toolbar.toggleToolbox(true);
          }
          // Otherwise create a new empty block matching the last one in the editor
          else {
            const newBlockType = useCurrentBlock
              ? type === 'base'
                ? BlockType.Section
                : BlockType.Question
              : targetBlock?.name;

            currEditor.blocks.insert(
              newBlockType,
              { totalBlocks: blockCount + 1, blockIndex: useCurrentBlock ? targetBlockIndex : blockCount, requestSent },
              useCurrentBlock ? undefined : targetBlock?.config,
              useCurrentBlock ? targetBlockIndex + 1 : blockCount
            );
          }
        }

        currEditor.toolbar.close();
      }
    },
    [editorRef, requestSent]
  );

  useMemo(() => {
    // Destroy editor and re-initialize when disabled status changes, so that editor components are re-initialized with disabled config values
    if (
      !editorRef.current ||
      (prevDisabled !== undefined && prevDisabled !== disabled) ||
      (prevReadOnly !== undefined && prevReadOnly !== readOnly) ||
      (prevData !== undefined && !_.isEqual(prevData, data) && canRefreshData)
    ) {
      if (canRefreshData && setCanRefreshData) setCanRefreshData(false);
      if (editorRef.current?.destroy) {
        editorRef.current.destroy();
      } else {
        const config: ICreateTemplateBlockConfig = {
          disabled: !!disabled,
          onCreateTemplate,
          onSave,
          readOnly,
          requestId
        };

        const editor = new EditorJs({
          holder: editorblock,
          tools: getEditorTools(config, type, token),
          defaultBlock: 'paragraph',
          readOnly: readOnly || previewing || disabled,
          data: { ...data, blocks: blocksWithOpenSections },
          onChange,
          onReady: () => {
            new DragDrop(editor);
            onReady?.();

            // Recommended method for removing tooltips @link: https://github.com/codex-team/editor.js/issues/1436.
            // Not great though, TODO: we should add a built in method for disabling tooltips on editor
            const tooltips = document.querySelectorAll('.ce-toolbar__plus, .ce-toolbar__settings-btn');
            tooltips.forEach((tooltip) => {
              tooltip.addEventListener('mouseenter', (event) => event.stopImmediatePropagation(), true);
              tooltip.addEventListener('mouseleave', (event) => event.stopImmediatePropagation(), true);
            });
          },
          minHeight: readOnly || previewing || disabled ? 0 : 5,
          placeholder: 'Add content here!',
          onQuickCreate: (count) => onQuickCreate({ count, currEditor: this, useCurrentBlock: true })
        });

        editorRef.current = editor;
      }
    }

    //Add a return function to handle cleanup
    return () => {
      if (editorRef.current && editorRef.current.destroy) {
        editorRef.current.destroy();
      }
    };
  }, [
    editorRef,
    prevDisabled,
    disabled,
    prevReadOnly,
    readOnly,
    prevData,
    data,
    canRefreshData,
    setCanRefreshData,
    editorblock,
    onCreateTemplate,
    onQuickCreate,
    onSave,
    requestId,
    type,
    token,
    blocksWithOpenSections,
    onChange,
    onReady
  ]);

  const quickCreateIconSize = nested ? 20 : 24;

  return (
    <div className={nested ? undefined : 'p-2'}>
      <div
        id={editorblock}
        className={`editor-container ml-auto ${nested ? '' : 'prose'} ${readOnly ? 'read-only' : ''}`}
      />
      {!disabled && !readOnly && !previewing && (
        <Button
          icon={
            <PlusIcon
              height={quickCreateIconSize}
              width={quickCreateIconSize}
              className="my-auto rounded-md bg-green-400 hover:bg-green-300 text-white mr-auto"
            />
          }
          className={`ce-toolbar__button !p-0 !text-green-400 hover:bg-opacity-60 max-w-32 !w-full ${
            nested ? 'text-sm ml-1' : ' ml-0.5'
          }`}
          onClick={() => onQuickCreate({ count: 1 })}
          variant="custom"
          text={quickCreateTitle}
        />
      )}
    </div>
  );
};

export const FormEditor = memo(Editor);
