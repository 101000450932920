import { gql } from '@apollo/client';
import { COMPANY_OVERVIEW_FRAGMENT } from '../companies/company.queries';

const TEMPLATE_SUMMARY_FRAGMENT = gql`
  fragment TemplateSummary on Template {
    _id
    blockType
    company {
      ...CompanyOverview
    }
    description
    deletedAt
    title
    type
    updatedAt
  }

  ${COMPANY_OVERVIEW_FRAGMENT}
`;

const TEMPLATE_FRAGMENT = gql`
  fragment TemplateDetails on Template {
    ...TemplateSummary
    blocks {
      data
      type
    }
  }

  ${TEMPLATE_SUMMARY_FRAGMENT}
`;

export const GET_TEMPLATE = gql`
  query GetTemplate($_id: ID!) {
    template(_id: $_id) {
      ...TemplateDetails
    }
  }

  ${TEMPLATE_FRAGMENT}
`;

export const GET_TEMPLATES = gql`
  query GetTemplates($types: [TemplateType!]!) {
    templates(types: $types) {
      ...TemplateSummary
    }
  }

  ${TEMPLATE_SUMMARY_FRAGMENT}
`;

export const CREATE_GLOBAL_TEMPLATE = gql`
  mutation CreateGlobalTemplate(
    $blocks: [RequestBlockTemplateInput!]!
    $version: String!
    $type: TemplateType!
    $title: String!
    $description: String
  ) {
    createGlobalTemplate(blocks: $blocks, version: $version, type: $type, title: $title, description: $description) {
      ...TemplateSummary
    }
  }

  ${TEMPLATE_SUMMARY_FRAGMENT}
`;

export const CREATE_TEMPLATE = gql`
  mutation CreateTemplate(
    $blocks: [RequestBlockTemplateInput!]!
    $version: String!
    $type: TemplateType!
    $title: String!
    $description: String
  ) {
    createTemplate(blocks: $blocks, version: $version, type: $type, title: $title, description: $description) {
      ...TemplateSummary
    }
  }

  ${TEMPLATE_SUMMARY_FRAGMENT}
`;

export const DELETE_GLOBAL_TEMPLATE = gql`
  mutation DeleteGlobalTemplate($_id: ID!) {
    deleteGlobalTemplate(_id: $_id) {
      ...TemplateSummary
    }
  }

  ${TEMPLATE_SUMMARY_FRAGMENT}
`;

export const DELETE_TEMPLATE = gql`
  mutation DeleteTemplate($_id: ID!) {
    deleteTemplate(_id: $_id) {
      ...TemplateSummary
    }
  }

  ${TEMPLATE_SUMMARY_FRAGMENT}
`;

export const RESTORE_TEMPLATE = gql`
  mutation RestoreTemplate($_id: ID!) {
    restoreTemplate(_id: $_id) {
      ...TemplateSummary
    }
  }

  ${TEMPLATE_SUMMARY_FRAGMENT}
`;

export const UPDATE_GLOBAL_TEMPLATE = gql`
  mutation UpdateGlobalTemplate($_id: ID!, $title: String!, $description: String, $isGlobal: Boolean) {
    updateGlobalTemplate(_id: $_id, title: $title, description: $description, isGlobal: $isGlobal) {
      ...TemplateSummary
    }
  }

  ${TEMPLATE_SUMMARY_FRAGMENT}
`;

export const UPDATE_TEMPLATE = gql`
  mutation UpdateTemplate($_id: ID!, $title: String!, $description: String) {
    updateTemplate(_id: $_id, title: $title, description: $description) {
      ...TemplateSummary
    }
  }

  ${TEMPLATE_SUMMARY_FRAGMENT}
`;
