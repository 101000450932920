import { useMemo, useState } from 'react';
import { FormContainer } from '../../../_core/form/form-container.component';
import { useUpdateClient } from '../client.service';
import { IUpdateClientEntitiesFormValues, UpdateClientEntitiesForm } from './update-client-entities-form.component';
import { IClient, IEntityMember } from '../../../../lib/types';
import { logError, sleep } from '../../../../lib/utils';
import { resetFormMessages } from '../../../_core/form/form.helpers';
import { useEntityOverviews } from '../../entities/entity.service';
import { Loader } from '../../../_core/loader.component';
import { ICloseableDialogProps } from '../../../_core/core.types';

interface IUpdateClientEntitiesContainerProps extends ICloseableDialogProps {
  client: IClient;
  members: IEntityMember[];
}

export const UpdateClientEntitiesContainer = ({ client, close, members }: IUpdateClientEntitiesContainerProps) => {
  const [success, setSuccess] = useState<string | null>(null);
  const [submitError, setSubmitError] = useState<string | null>(null);

  const { entities } = useEntityOverviews();
  const { updateClient } = useUpdateClient();

  const handleUpdate = async ({ entities }: IUpdateClientEntitiesFormValues) => {
    try {
      await resetFormMessages({ setSubmitError, setSuccess });

      await updateClient({ _id: client._id, entities: entities?.map(({ value }) => value) });
      setSubmitError(null);
      setSuccess('Successfully updated contact!');
      sleep(250).then(close);
    } catch (err) {
      const errMsg = 'Unable to update contact.';
      logError(errMsg, (err as Error).message);
      setSuccess(null);
      setSubmitError(errMsg);
    }
  };

  const currEntities = useMemo(
    () =>
      members
        .filter(({ deletedAt }) => !deletedAt)
        .map(({ entity }) => entity)
        .filter((e) => !e.deletedAt),
    [members]
  );

  return (
    <FormContainer
      error={submitError}
      setError={setSubmitError}
      success={success}
      setSuccess={setSuccess}
      title="Update Contact's Associated Companies"
    >
      {!entities ? (
        <Loader />
      ) : (
        <UpdateClientEntitiesForm
          currEntities={currEntities}
          entities={entities?.filter((e) => !e.deletedAt) ?? []}
          client={client}
          onSubmit={handleUpdate}
        />
      )}
    </FormContainer>
  );
};
