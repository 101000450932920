import { Form, Formik, FormikProps } from 'formik';
import { FormInputWithLabel } from '../../../_core/form/input/form-labelled-input.component';
import { FormButton } from '../../../_core/form/button/form-button.component';
import { IRIFormProps } from '../../../_core/form/form.types';
import { ILocation } from '../../../../lib/types';
import { ISelectedIDProps } from '../../../../lib/query.types';
import { IMultiSelectOption } from '../../../_core/input/multiselect-input.component';

interface IEditLocationsFormFieldProps {
  ids: IMultiSelectOption[];
}

interface IEditLocationsFormProps extends IRIFormProps<ISelectedIDProps> {
  locations: ILocation[];
  selected: ILocation[];
}

export const EditLocationsForm = ({ locations, onSkip, onSubmit, selected }: IEditLocationsFormProps) => {
  return (
    <Formik
      initialValues={
        {
          ids: selected.map(({ _id: value, name: label, deletedAt }) => ({
            label,
            value,
            secondaryLabel: deletedAt ? 'INACTIVE' : undefined
          }))
        } as IEditLocationsFormFieldProps
      }
      onSubmit={({ ids }) => onSubmit({ ids: ids.map(({ value }) => value) })}
    >
      {(props: FormikProps<IEditLocationsFormFieldProps>) => (
        <Form action="#" method="POST" className="sm:max-w-xl mx-auto space-y-4 mt-10">
          <FormInputWithLabel
            id="ids"
            name="ids"
            label="Locations"
            placeholder="Edit locations"
            value={props.values.ids}
            type="multiselect"
            multiSelectProps={{
              options: locations.filter((l) => !l.deletedAt).map((d) => ({ label: d.name, value: d._id }))
            }}
          />
          <div className="flex row justify-around">
            {!!onSkip && <FormButton size="large" onPress={onSkip} text="Skip" type="button" variant="secondary" />}
            <FormButton size="large" loading={props.isSubmitting} text="Update" />
          </div>
        </Form>
      )}
    </Formik>
  );
};
