import { useFormFieldMeta } from './form-field-meta';

interface IFormLabelProps {
  noPadding?: boolean;
  label: string;
  name: string;
}

export const FormLabel = ({ noPadding, label, name }: IFormLabelProps) => {
  const { hasError } = useFormFieldMeta(name);

  const fieldStateClasses = hasError ? 'text-red-600' : 'text-gray-900';

  return (
    <label htmlFor={name} className={`block text-sm leading-6 ${noPadding ? '' : 'pb-1.5'} ${fieldStateClasses}`}>
      {label}
    </label>
  );
};
