import { IRequestOverview, REQUEST_STATUS, requestStatusLabels } from '../../../lib/types';
import { StatusIcon } from '../../_editor/_core/status-icon.component';

interface IRequestStatusIcon {
  inline?: boolean;
  request: IRequestOverview;
}

export const RequestStatusIcon = ({ inline, request }: IRequestStatusIcon) => {
  const statusLabelProps =
    requestStatusLabels[request.deletedAt ? REQUEST_STATUS.ARCHIVED : request.status ?? REQUEST_STATUS.DRAFT];

  return (
    <StatusIcon inline={inline} slim={!inline} backgroundColor={statusLabelProps.color}>
      {statusLabelProps.title}
    </StatusIcon>
  );
};
