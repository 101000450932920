export enum QuestionType {
  TEXT = 'text',
  // TODO: short txt
  TEXTAREA = 'textarea',
  // TODO: below txt
  NUMBER = 'number',
  CURRENCY = 'currency',
  DATE = 'date',
  EMAIL = 'email',
  PHONE = 'phone',
  URL = 'url'
  // TODO: percentage
  // TODO: website
}
export const QuestionTypeLabels: Record<QuestionType, string> = {
  [QuestionType.TEXT]: 'txt',
  // short txt
  [QuestionType.TEXTAREA]: 'multi txt',
  [QuestionType.NUMBER]: 'num',
  [QuestionType.CURRENCY]: '$',
  [QuestionType.DATE]: 'date',
  [QuestionType.EMAIL]: 'email',
  [QuestionType.PHONE]: 'phone',
  [QuestionType.URL]: 'url'
  // percentage
  // website
};
export const QuestionTypes = Object.values(QuestionType);
