const letterize = (num: number, capitalize = false) => {
  const letterCount = Math.ceil(num / 26);
  const char = String.fromCharCode(96 + (num % 26));

  let result = '';
  for (let i = 0; i < letterCount; i++) result += char;
  return capitalize ? result.toUpperCase() : result.toLowerCase();
};

// const romanize = (num: number) => {
//   const digits = String(num).split('');
//   if (digits) {
//     let roman = '';
//     let i = 3;
//     while (i--) {
//       const popped = digits.pop();
//       if (popped) roman = (ROMAN_NUMERAL_KEYS[+popped + i * 10] || '') + roman;
//     }
//     return Array(+digits.join('') + 1).join('m') + roman;
//   }
//   return 'i';
// }

export const determineLocalTitle = (nestedDepth: number, localTitleIndex: number) => {
  let localTitle: string;
  const remaining = nestedDepth % 2;

  // Use Capital letters for sections then alternate between numbers and lower case letters for descending components
  if (nestedDepth === 1) localTitle = letterize(localTitleIndex + 1, true);
  else if (remaining) localTitle = letterize(localTitleIndex + 1);
  else localTitle = String(localTitleIndex + 1);

  return localTitle;
};
