import { LowlightTextWithSubtitle } from '../typography';

export const TableDate = ({ centered, date, showTime }: { centered?: boolean; date: Date; showTime?: boolean }) => (
  <LowlightTextWithSubtitle
    centered={centered}
    subtitle={
      showTime && new Date(date).toDateString() === new Date().toDateString()
        ? new Date(date).toLocaleTimeString('en-CA', { hour: 'numeric', minute: '2-digit' })
        : undefined
    }
  >
    {new Date(date).toLocaleDateString('en-CA', { year: '2-digit', day: 'numeric', month: 'numeric' })}
  </LowlightTextWithSubtitle>
);
