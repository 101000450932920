import { NavLink, useNavigate } from 'react-router-dom';
import { UserIcon, PowerIcon, BuildingStorefrontIcon } from '@heroicons/react/20/solid';

// Utils
import logo from '../../../../assets/imgs/logoWhitePrimary.png';
import shortLogo from '../../../../assets/imgs/logoShortWhitePrimary.png';
import { ROUTE_PATHS } from '../../../_routes';
import { ScreenReaderText } from '../typography';

// Components
import { FirmSelector } from './firm-selector.component';
import { ForceRegisterPromptsContainer } from './force-register-prompts/force-register-prompts.container';
import { MenuItem } from './menu/menu-item.component';
import { useLogout } from '../../domains/auth/auth.service';
import { Tooltip } from '../tooltip.component';
import { useConfirm } from '../confirm/confirm.utils';
import { ACTIVE_HEADER_CLASS } from './page-header-consts';
import { ICompanyValidationProps } from './force-register-prompts/force-register-prompts.component';
import { Button } from '../button/button.component';
import { useAuth0WithRedirect } from '../../_auth/auth.utils';

const LINK_CLASS =
  'relative inline-flex items-center justify-center px-4 text-gray-50 hover:bg-gray-700 hover:text-white focus:outline-none h-full';

const getLinkClass = ({ isActive }: { isActive?: boolean }) => `${LINK_CLASS} ${isActive ? ACTIVE_HEADER_CLASS : ''}`;

export interface IPageHeaderProps extends ICompanyValidationProps {
  fullSizeLogo?: boolean;
  unauthenticatedMode?: boolean;
}

export const PageHeader = ({ fullSizeLogo, ignoreCompanyValidation, unauthenticatedMode }: IPageHeaderProps) => {
  const { ConfirmationDialog, confirm } = useConfirm('Continue signing out?');
  const { handleLogout } = useLogout();
  const { isAuthenticated, loginWithRedirect } = useAuth0WithRedirect();
  const navigate = useNavigate();

  return (
    <>
      <div className="bg-secondary sticky top-0 drop-shadow-lg z-50">
        <div className="mx-auto pl-4 flex justify-between h-10">
          <div className="flex md:gap-16 sm:gap-8 w-full items-center">
            <button
              className="flex h-8 items-center justify-between px-4 sm:px-0"
              onClick={() => navigate(isAuthenticated ? ROUTE_PATHS.DASHBOARD : ROUTE_PATHS.LANDING)}
              type="button"
            >
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <img
                    className={`object-contain ${fullSizeLogo ? 'h-8 w-40' : 'h-8 w-12'}`}
                    src={fullSizeLogo ? logo : shortLogo}
                    alt="Request IQ Logo"
                  />
                </div>
              </div>
            </button>
            <div className="container flex">
              {!unauthenticatedMode && (
                <div className="flex items-center flex-grow">
                  <MenuItem label="Home" to={ROUTE_PATHS.DASHBOARD} />
                  <MenuItem label="Contacts" to={ROUTE_PATHS.CONTACTS} />
                  <MenuItem label="Templates" to={ROUTE_PATHS.TEMPLATES} />
                  <div className="flex-grow" />
                  <FirmSelector />
                </div>
              )}
              <ForceRegisterPromptsContainer ignoreCompanyValidation={ignoreCompanyValidation} />
            </div>
            {isAuthenticated ? (
              <div className="flex h-full">
                <Tooltip singleLine text="User Profile" direction="bottom" distance="md" className="-right-4">
                  <NavLink end className={getLinkClass} to={ROUTE_PATHS.PROFILE}>
                    <ScreenReaderText>Open profile</ScreenReaderText>
                    <UserIcon className="block h-6 w-6" aria-hidden="true" />
                  </NavLink>
                </Tooltip>
                <Tooltip singleLine text="Organization Profile" direction="bottom" distance="md" className="-right-10">
                  <NavLink className={getLinkClass} to={ROUTE_PATHS.FIRM_PROFILE}>
                    <ScreenReaderText>Open account settings</ScreenReaderText>
                    <BuildingStorefrontIcon className="block h-6 w-6" aria-hidden="true" />
                  </NavLink>
                </Tooltip>
                <Tooltip singleLine text="Sign out" direction="bottom" distance="md" className="right-0">
                  <button
                    onClick={() =>
                      confirm().then((confirmed) => {
                        if (confirmed) handleLogout();
                      })
                    }
                    className={LINK_CLASS}
                  >
                    <ScreenReaderText>Sign out</ScreenReaderText>
                    <PowerIcon className="block h-6 w-6" aria-hidden="true" />
                  </button>
                </Tooltip>
              </div>
            ) : (
              <div className="flex h-full w-full justify-end items-center mr-8 gap-4">
                <Button onClick={() => loginWithRedirect()} size="medium" slim variant="custom" textClass="text-white">
                  Login
                </Button>
                <Button onClick={() => loginWithRedirect('signup')} size="large" slim>
                  Sign Up
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
      <ConfirmationDialog />
    </>
  );
};
