import { BlockToolConstructorOptions } from '@editorjs/editorjs';
import { IUploadBlockConfig, Upload } from './upload.component';
import { UploadData } from './upload.types';
import { BlockTitles, BlockType } from '../_core/editor.const';

export class UploadList extends Upload {
  constructor(props: BlockToolConstructorOptions<UploadData, IUploadBlockConfig>) {
    const { config, ...rest } = props;
    if (!config) throw new Error('Upload list config was empty');

    const newConfig: IUploadBlockConfig = { ...config, mode: 'upload-list' };
    super({ ...rest, config: newConfig });
  }

  static get toolbox() {
    return {
      title: BlockTitles[BlockType.DocUploadList],
      icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><!--!Font Awesome Free 6.7.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2025 Fonticons, Inc.--><path d="M64 32C64 14.3 49.7 0 32 0S0 14.3 0 32l0 96L0 384c0 35.3 28.7 64 64 64l192 0 0-64L64 384l0-224 192 0 0-64L64 96l0-64zM288 192c0 17.7 14.3 32 32 32l224 0c17.7 0 32-14.3 32-32l0-128c0-17.7-14.3-32-32-32l-98.7 0c-8.5 0-16.6-3.4-22.6-9.4L409.4 9.4c-6-6-14.1-9.4-22.6-9.4L320 0c-17.7 0-32 14.3-32 32l0 160zm0 288c0 17.7 14.3 32 32 32l224 0c17.7 0 32-14.3 32-32l0-128c0-17.7-14.3-32-32-32l-98.7 0c-8.5 0-16.6-3.4-22.6-9.4l-13.3-13.3c-6-6-14.1-9.4-22.6-9.4L320 288c-17.7 0-32 14.3-32 32l0 160z"/></svg>'
    };
  }
}
