import { EntityProfilePage } from './entity-profile-page.component';
import { Loader } from '../../_core/loader.component';
import { useNavigate, useParams } from 'react-router-dom';
import { useDeleteEntity, useEntity, useRestoreEntity } from '../../domains/entities/entity.service';
import { useCurrMember } from '../../domains/member/member.service';
import { useEntityMembers } from '../../domains/entity-members/entity-members.service';
import { useClients } from '../../domains/clients/client.service';
import { useCurrCompany } from '../../domains/companies/company.service';
import { ROUTE_PATHS } from '../../../_routes';
import { useEffect } from 'react';
import { showError } from '../../../lib/utils';

export const EntityProfilePageContainer = () => {
  const navigate = useNavigate();
  const params = useParams();

  const { entity } = useEntity({ _id: params.entityId ?? '' });
  const { clients } = useClients();
  const { entityMembers } = useEntityMembers(params);

  const { company, isPersonal } = useCurrCompany();
  const { isAdmin } = useCurrMember();
  const { deleteEntity, loading: deleting } = useDeleteEntity();
  const { restoreEntity, loading: restoring } = useRestoreEntity();

  useEffect(() => {
    if (entity && company && entity.company?._id !== company._id) navigate(ROUTE_PATHS.DASHBOARD);
  }, [company, entity, navigate]);

  if (!entity || !company) return <Loader />;

  return (
    <div className="flex min-h-full flex-1 flex-col items-center">
      <EntityProfilePage
        clients={clients}
        deleteEntity={(props) =>
          deleteEntity(props).catch((err) => showError('Unable to delete company', err as Error))
        }
        deleting={deleting}
        entity={entity}
        invites={[]}
        isAdmin={isAdmin}
        isPersonalCompany={isPersonal}
        members={entityMembers}
        restoreEntity={(props) =>
          restoreEntity(props).catch((err) => showError('Unable to restore company', err as Error))
        }
        restoring={restoring}
      />
    </div>
  );
};
