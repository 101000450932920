import { useCallback, useMemo } from 'react';
import toast from 'react-hot-toast';
import { Button } from '../../../_core/button/button.component';
import { IButtonDropdownOptionProps } from '../../../_core/button/button-dropdown-option-component';
import { IDepartment, IEntity, ILocation } from '../../../../lib/types';
import { showError } from '../../../../lib/utils';
import { useDeleteEntity, useRestoreEntity } from '../../../domains/entities/entity.service';
import { IPersonalCompanyProps } from '../../../_core/core.types';
import { useNavigate } from 'react-router-dom';
import { ROUTE_PATHS } from '../../../../_routes';
import { IEntityTableClientModeProps } from './entity-table.types';
import { useDeleteEntityMember } from '../../../domains/entity-members/entity-members.service';

type NullableEntity = IEntity | null;

export interface IEntityActionProps {
  entity: IEntity;
}

export interface IEntityActionButtonProps extends IEntityActionProps, IPersonalCompanyProps {
  clientModeConfig?: IEntityTableClientModeProps; // When viewing the entity table from the client-profile, we want actions on entities to be tailored to that client
  departments?: IDepartment[];
  locations?: ILocation[];
  setShowEditDepartmentsModal: React.Dispatch<React.SetStateAction<NullableEntity>>;
  setShowEditLocationsModal: React.Dispatch<React.SetStateAction<NullableEntity>>;
}

export const EntityActionButton: React.FC<IEntityActionButtonProps> = ({
  clientModeConfig,
  departments,
  entity,
  isPersonalCompany,
  locations,
  setShowEditDepartmentsModal,
  setShowEditLocationsModal
}) => {
  const navigate = useNavigate();

  const { deleteEntity, loading: deletingEntity } = useDeleteEntity();
  const { deleteEntityMember, loading: deletingEntityMember } = useDeleteEntityMember();
  const { restoreEntity, loading: restoringEntity } = useRestoreEntity();

  const editEntityDepartmentsAction: IButtonDropdownOptionProps = useMemo(
    () => ({
      disabled: !departments?.length,
      onClick: () => setShowEditDepartmentsModal(entity),
      text: 'Edit Departments'
    }),
    [departments?.length, entity, setShowEditDepartmentsModal]
  );

  const editEntityLocationsAction: IButtonDropdownOptionProps = useMemo(
    () => ({
      disabled: !locations?.length,
      onClick: () => setShowEditLocationsModal(entity),
      text: 'Edit Locations'
    }),
    [entity, locations?.length, setShowEditLocationsModal]
  );

  const onRestoreEntity = useCallback(async () => {
    if (entity.deletedAt)
      try {
        await restoreEntity(entity);
        toast.success(`Restored Company`);
      } catch (err) {
        showError('Unable to restore company', err as Error);
      }
  }, [entity, restoreEntity]);

  const restoreEntityAction: IButtonDropdownOptionProps = useMemo(
    () => ({ onClick: onRestoreEntity, text: 'Restore' }),
    [onRestoreEntity]
  );

  const onDeleteEntity = useCallback(async () => {
    try {
      await deleteEntity(entity);
      toast.success(`Deleted Company`);
    } catch (err) {
      showError('Unable to delete company', err as Error);
    }
  }, [deleteEntity, entity]);

  const deleteEntityAction: IButtonDropdownOptionProps = useMemo(
    () => ({ onClick: onDeleteEntity, text: 'Delete' }),
    [onDeleteEntity]
  );

  const entityMember = useMemo(
    () => clientModeConfig?.members?.find((m) => m.entity._id === entity._id),
    [clientModeConfig?.members, entity._id]
  );

  const onRemoveEntity = useCallback(async () => {
    try {
      if (entityMember) {
        await deleteEntityMember({ _id: entityMember?._id });
        toast.success(`Removed Company`);
      } else toast.error('Failed to update Contact');
    } catch (err) {
      showError('Unable to remove company', err as Error);
    }
  }, [deleteEntityMember, entityMember]);

  const removeEntityAction: IButtonDropdownOptionProps = useMemo(
    () => ({ onClick: onRemoveEntity, text: 'Remove' }),
    [onRemoveEntity]
  );

  const viewAction: IButtonDropdownOptionProps = useMemo(
    () => ({ onClick: () => navigate(ROUTE_PATHS.ENTITY_PROFILE + '/' + entity?._id), text: 'View Entity' }),
    [entity?._id, navigate]
  );

  const options = useMemo(() => {
    const newOptions: IButtonDropdownOptionProps[] =
      isPersonalCompany || !!clientModeConfig
        ? [viewAction]
        : [viewAction, editEntityDepartmentsAction, editEntityLocationsAction];

    if (entityMember && !entityMember.deletedAt) newOptions.push(removeEntityAction);
    else newOptions.push(entity.deletedAt ? restoreEntityAction : deleteEntityAction);

    return newOptions;
  }, [
    clientModeConfig,
    deleteEntityAction,
    editEntityDepartmentsAction,
    editEntityLocationsAction,
    entity.deletedAt,
    entityMember,
    isPersonalCompany,
    removeEntityAction,
    restoreEntityAction,
    viewAction
  ]);

  return (
    <Button
      slim
      dropdown={{ options }}
      text="ACTION"
      size="xl"
      loading={deletingEntity || restoringEntity || deletingEntityMember}
    />
  );
};
