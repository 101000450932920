import { useAuth0 } from '@auth0/auth0-react';

export const useAuth0WithRedirect = () => {
  const { loginWithRedirect, ...rest } = useAuth0();

  // @ts-ignore
  const startAuth0 = (screen_hint: 'login' | 'signup' = 'login') =>
    loginWithRedirect({ authorizationParams: { screen_hint } }).then(() => console.log({ screen_hint }));

  return { ...rest, loginWithRedirect: startAuth0 };
};
