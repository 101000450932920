import { useState } from 'react';
import { FormContainer } from '../../../_core/form/form-container.component';
import {
  ISendClientInvitesContainerProps,
  SendClientInvitesNestedContainer
} from './send-client-invites-form-nested.container';
import { useClientOverviews } from '../../clients/client.service';
import { Loader } from '../../../_core/loader.component';
import { useEntityMembers } from '../../entity-members/entity-members.service';

export const SendClientInvitesContainer = ({ entity, ...rest }: ISendClientInvitesContainerProps) => {
  const [success, setSuccess] = useState<string | null>(null);
  const [submitError, setSubmitError] = useState<string | null>(null);

  const { clients } = useClientOverviews();
  const { entityMembers } = useEntityMembers({ entityId: entity?._id });

  return (
    <FormContainer
      error={submitError}
      setError={setSubmitError}
      success={success}
      setSuccess={setSuccess}
      title={'Add Contacts' + (entity ? ` to ${entity.name}` : '')}
    >
      {!!clients && (!entity || !!entityMembers) ? (
        <SendClientInvitesNestedContainer
          {...rest}
          onError={(e) => setSubmitError(e ?? null)}
          onSuccess={(s) => setSuccess(s ?? null)}
          clients={clients}
          entity={entity}
          entityMembers={entityMembers}
        />
      ) : (
        <Loader />
      )}
    </FormContainer>
  );
};
