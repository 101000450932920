import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useFetchCurrUser } from '../../domains/users/user.service';
import { Loader } from '../../_core/loader.component';
import { useAcceptInvite, useInvite } from '../../domains/invite/invite.service';
import { ROUTE_PATHS } from '../../../_routes';
import { useEffect, useMemo, useState } from 'react';
import { showError } from '../../../lib/utils';
import { INVITE_STATUS } from '../../../lib/types';

// Will force join with code if possible
export const JoinWithCodePageContainer = () => {
  const params = useParams();
  const navigate = useNavigate();

  const { isAuthenticated } = useAuth0();
  const { user } = useFetchCurrUser();

  const { acceptInvite } = useAcceptInvite();

  const code = params.code ?? '';
  const { invite } = useInvite(code);

  const [accepting, setAccepting] = useState(false);

  const foundInvite = useMemo(() => user && invite, [invite, user]);

  const isWrongUserForInvite = useMemo(
    () => foundInvite && user?.email !== invite?.email,
    [foundInvite, invite?.email, user?.email]
  );

  useEffect(() => {
    if (
      !accepting &&
      isAuthenticated &&
      foundInvite &&
      invite?.status === INVITE_STATUS.PENDING &&
      !isWrongUserForInvite
    ) {
      setAccepting(true);
      acceptInvite({ code })
        .then(() => navigate(ROUTE_PATHS.DASHBOARD))
        .catch((err) => showError('Failed to accept invite', err as Error));
    }
  }, [acceptInvite, accepting, code, foundInvite, invite?.status, isAuthenticated, isWrongUserForInvite, navigate]);

  if (!isAuthenticated || isWrongUserForInvite) return <Navigate to={ROUTE_PATHS.INVITE + '/' + code} />;
  if (!invite) console.log({ foundInvite, invite });
  return (
    <div className="flex flex-col flex-1 items-center justify-center text-center">
      {!invite || invite?.status === INVITE_STATUS.PENDING ? (
        <>
          <h1 className="mb-4">{invite ? 'Accepting invite...' : 'Fetching invite...'}</h1>
          <Loader />
        </>
      ) : (
        <h1 className="mb-4 text-error">
          Invite is no longer valid.
          <br />
          Please request a new invite.
        </h1>
      )}
    </div>
  );
};
