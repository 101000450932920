import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BuildingOfficeIcon, EnvelopeIcon, ClipboardIcon, PlayCircleIcon } from '@heroicons/react/20/solid';

// Utils
import { ROUTE_PATHS } from '../../../_routes';
import logo from '../../../../assets/imgs/logoSecondaryPrimary.png';

// Services
import { useCurrCompany, useUpdateCompany, useUserCompanies } from '../../domains/companies/company.service';

// Components
import { RegisterCompanySteps } from '../../domains/companies/register/register-company-steps.component';
import { OnboardingNavIcon } from './onboarding-nav-icon.component';

export const OnboardingPage = () => {
  const navigate = useNavigate();
  const contentRef = useRef<HTMLDivElement>(null);
  const [activeStep, setActiveStep] = useState(0);
  const [initialized, setInitialized] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const { updateCompany } = useUpdateCompany();
  const { activeCompanies } = useUserCompanies({});
  const { company } = useCurrCompany();

  useEffect(() => {
    if (activeCompanies && !initialized) {
      setActiveStep(activeCompanies?.length ? 1 : 0);
      setInitialized(true);
    }

    if (company?.completedOnboarding) {
      navigate(ROUTE_PATHS.DASHBOARD);
    }
  }, [activeCompanies, setActiveStep, initialized, setInitialized, company, navigate]);

  return (
    <div ref={contentRef} className="flex min-h-full flex-1 flex-row">
      <div className="p-8 md:block sm:hidden">
        <div className="flex items-center mb-8 border-b border-dashed pb-8">
          <div className="flex-shrink-0 mr-2">
            <img className="h-16 w-64" src={logo} alt="Request IQ" />
          </div>
        </div>
        <OnboardingNavIcon
          active={!activeStep}
          title="Getting Started"
          subtitle="Join or create an organization"
          Icon={PlayCircleIcon}
        />
        <OnboardingNavIcon
          active={activeStep === 1}
          title="Organization information"
          subtitle="Let's get started by creating your organization"
          Icon={BuildingOfficeIcon}
        />
        <OnboardingNavIcon
          active={activeStep === 2}
          title="Invite Team Members"
          subtitle="Add others who will be working with you"
          Icon={EnvelopeIcon}
        />
        <OnboardingNavIcon
          active={activeStep === 3}
          title="Create & Send Requests"
          subtitle="Start sending requests out to your clients"
          Icon={ClipboardIcon}
        />
      </div>
      <div className="bg-white p-8 flex-grow">
        <div className="mx-auto mt-8" style={{ maxWidth: '400px' }}>
          {!activeCompanies ? (
            <div>Loading...</div>
          ) : (
            <RegisterCompanySteps
              contentRef={contentRef}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              onError={(e) => setError(e ?? null)}
              onFinish={async (to?: string) => {
                await updateCompany({ completedOnboarding: true });
                navigate(to || ROUTE_PATHS.DASHBOARD);
              }}
            />
          )}
          {error && <p className="text-red-500 text-sm text-left mt-4">{error}</p>}
        </div>
      </div>
    </div>
  );
};
