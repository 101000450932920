import { useCallback, useEffect, useMemo, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import EditorJs, { OutputBlockData, OutputData } from '@editorjs/editorjs';
import { uniqueId } from 'lodash';
import { ChatBubbleLeftIcon, ExclamationCircleIcon } from '@heroicons/react/20/solid';
import { ISectionData, SectionType } from '../../_editor/section/section.types';
import {
  CreateTemplateContainer,
  ICreateTemplateContainerProps
} from '../../domains/template/create/create-template-form.container';
import { IRequest, REQUEST_NOTIFY_OPTION, REQUEST_STATUS, TEMPLATE_TYPE } from '../../../lib/types';
import { ICreateBlockTemplateParams } from '../../_editor/_core/create-template-block';
import { Button } from '../../_core/button/button.component';
import { FormEditor } from '../FormBuilderPage/form-editor.component';
import { RIDialog } from '../../_core/dialog/dialog.component';
import { ROUTE_PATHS } from '../../../_routes';
import { ArrowLeftCircleIcon, Cog6ToothIcon } from '@heroicons/react/20/solid';
import { EditRequestContainer } from '../../domains/request/edit/edit-request.container';
import { RequestActionButton } from '../../domains/request/request-action-button';
import { DownloadRequestAssetsButtonContainer } from '../../domains/request/download-request-assets-button';
import { convertRemToPixels, showError, sleep, usePrev } from '../../../lib/utils';
import { SendRequestMessageContainer } from '../../domains/conversation/send-request-message/send-request-message.container';
import { useSendRequest } from '../../domains/request/request.service';
import toast from 'react-hot-toast';
import { EditorSaveResult } from '../../_editor/_core/types';
import { useExitPrompt } from '../../_core/utils/browser';
import { RequestHeaderText } from './request-header-text.component';
import { RequestStatusIcon } from './request-status-icon';

const DEFAULT_SECTION_FORM_DATA: OutputBlockData<string, ISectionData> = {
  id: uniqueId(),
  type: 'section',
  data: {
    completed: false,
    filled: false,
    required: false,
    localTitle: '1)',
    label: 'First Section',
    forceOpen: true,
    outputData: { blocks: [] },
    type: SectionType.SECTION
  }
};

interface IRequestPage {
  canSave: boolean;
  hasChanged: boolean;
  editorRef: React.MutableRefObject<EditorJs | undefined>;
  onSave: () => Promise<EditorSaveResult>;
  readOnly?: boolean;
  request: IRequest;
  saving: boolean;
  token?: string;
}

export const RequestPage = ({
  canSave,
  hasChanged,
  editorRef,
  onSave,
  readOnly,
  request,
  saving,
  token
}: IRequestPage) => {
  const isClosed = request.status === REQUEST_STATUS.CLOSED;
  const isSent = useMemo(() => request.status === REQUEST_STATUS.SENT, [request.status]);
  const showRespond = isSent && !!token;

  const navigate = useNavigate();

  const [template, setTemplate] = useState<ICreateTemplateContainerProps | null>(null);
  const [canRefreshData, setCanRefreshData] = useState(false);
  const [editRequest, setEditRequest] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [previewing, setPreviewing] = useState(readOnly ?? isSent);
  const [showConversation, setShowConversation] = useState(false);
  const [preppingSendRequest, setPreppingSendRequest] = useState(false);

  useEffect(() => setPreviewing(readOnly ?? isSent), [isSent, readOnly]);

  const { sendRequest, loading: sendingRequest } = useSendRequest();

  const scrollBlockIntoView = (id: string, retries = 0) => {
    if (retries > 10) {
      console.log('Abandon attempt to scroll block into view');
      return;
    }

    const scrollToBlock = document.querySelector(`div[data-id="${id}"]`);
    if (scrollToBlock) scrollToBlock.scrollIntoView({ behavior: 'instant' });
    else sleep(100).then(() => scrollBlockIntoView(id, retries + 1));
  };

  const onTogglePreview = useCallback(
    async (state?: boolean) => {
      // Top of section area based on sticky header height and typical size of section header
      const topOfSectionArea = convertRemToPixels(4.75) + convertRemToPixels(2.5);

      const blocks = document.querySelectorAll('.ce-block');
      const firstBlockInView = [...blocks.entries()].find((b) => {
        const e = b[1] as HTMLElement;
        const bounds = e.getBoundingClientRect();
        return bounds.y > topOfSectionArea;
      })?.[1] as HTMLElement;

      setPreviewing((v) => state ?? !v);
      editorRef.current?.readOnly?.toggle(state);

      if (firstBlockInView?.dataset.id) sleep(100).then(() => scrollBlockIntoView(firstBlockInView.dataset.id ?? ''));
    },
    [editorRef]
  );

  const onClearRequest = useCallback(async () => {
    setCanRefreshData(true);
    onTogglePreview(false);
  }, [onTogglePreview]);

  const prevRequestStatus = usePrev(request.status);

  useEffect(() => {
    if (isReady && editorRef.current)
      if (prevRequestStatus === REQUEST_STATUS.CLOSED && !isClosed)
        // Toggle edit mode when re-opening request
        onTogglePreview(false);
      // Toggle ready only on closed
      else if (prevRequestStatus !== REQUEST_STATUS.CLOSED && isClosed && !previewing) onTogglePreview(true);
  }, [editorRef, isClosed, isReady, onTogglePreview, prevRequestStatus, previewing, request.status]);

  const onCreateRequestTemplate = () => {
    onSave()
      .then(({ data }) => {
        if (data) setTemplate({ data, type: TEMPLATE_TYPE.REQUEST, version: data.version ?? EditorJs.version });
        else toast.error('Failed to save request and create template');
      })
      .catch((err) => showError('Failed to save request', err));
  };

  const onCreateBlockTemplate = ({ data, type = TEMPLATE_TYPE.SECTION }: ICreateBlockTemplateParams) => {
    setTemplate({ data: { blocks: [{ data: data.data, type: data.type }] }, type, version: EditorJs.version });
  };

  const editorData: OutputData = {
    time: request.time,
    version: request.version,
    blocks: request.blocks ?? [DEFAULT_SECTION_FORM_DATA]
  };

  useExitPrompt({ isDirty: hasChanged, onCancel: () => onSave() });

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center sticky">
      <div id="request-actions" className="w-full bg-white sticky drop-shadow-lg top-10 z-40">
        <div className="max-w-[90%] w-full flex items-center justify-between py-1 mx-auto">
          <div className="flex flex-1 gap-4 items-center">
            {!readOnly && (
              <>
                <Button
                  slim
                  className="hover:opacity-50 hover:ring hover:ring-inset hover:ring-black"
                  containerClass="h-7"
                  icon={<ArrowLeftCircleIcon height={24} width={24} color="black" />}
                  onClick={() => navigate(ROUTE_PATHS.DASHBOARD)}
                  size="fit"
                  variant="custom"
                />
                <Button
                  slim
                  className="hover:opacity-50 hover:ring hover:ring-inset hover:ring-black"
                  containerClass="h-7"
                  icon={<Cog6ToothIcon height={24} width={24} color="black" />}
                  onClick={() => setEditRequest(true)}
                  size="fit"
                  variant="custom"
                />
              </>
            )}
            {readOnly ? (
              <>
                {request.status === REQUEST_STATUS.CLOSED && (
                  <h3 className="bg-secondary py-1 px-2 text-sm text-white">Closed</h3>
                )}
              </>
            ) : (
              <>
                <RequestHeaderText>
                  TO:{' '}
                  {request.assignedTo?.name || request.assignedTo?.user.name
                    ? (request.assignedTo?.name ?? request.assignedTo?.user.name) + ' '
                    : ''}
                  {request.assignedTo ? `(${request.assignedTo?.user.email})` : 'None'}
                </RequestHeaderText>
                {!!request.entity && <RequestHeaderText>{request.entity.name}</RequestHeaderText>}
              </>
            )}
          </div>
          <div className="relative flex h-7">
            {showRespond && (
              <Button
                slim
                hideEndMargin
                icon={<ChatBubbleLeftIcon width={24} height={24} className="mr-2" />}
                variant="secondary"
                onClick={() => setShowConversation(true)}
                text="Respond"
              />
            )}
            {!readOnly && (
              <>
                <div className={`w-36 inline-block ${showRespond ? 'ml-4' : ''}`}>
                  <RequestActionButton
                    slim
                    loading={sendingRequest}
                    hideViewAction
                    reloadPageState
                    request={request}
                    togglePreview={onTogglePreview}
                    onClearRequest={onClearRequest}
                    onCreateTemplate={() => onCreateRequestTemplate()}
                    onSave={onSave}
                    onSendRequest={() => {
                      setPreppingSendRequest(true);
                      setShowConversation(true);
                    }}
                    onResendRequest={() => setShowConversation(true)}
                    hasChanged={hasChanged}
                  />
                </div>
                {!isClosed && (
                  <div className="inline-block ml-4">
                    <Button
                      slim
                      onClick={() => onTogglePreview()}
                      size="medium"
                      text={previewing ? 'Edit' : 'Preview'}
                      variant="secondary"
                    />
                  </div>
                )}
              </>
            )}
            {canSave && (
              <div className={`inline-block ${showRespond || !readOnly ? 'ml-4' : ''}`}>
                <Button
                  slim
                  disabled={!hasChanged}
                  loading={saving}
                  onClick={async () =>
                    onSave().then((data) => {
                      toast.success(data.hasChanged ? 'Saved request successfully' : 'Request already up to date');
                      return data;
                    })
                  }
                  size="medium"
                  text="Save"
                  icon={
                    hasChanged ? (
                      <ExclamationCircleIcon width={16} height={16} color="gold" style={{ marginRight: 2 }} />
                    ) : undefined
                  }
                />
              </div>
            )}
            <DownloadRequestAssetsButtonContainer
              request={request}
              onSave={onSave}
              requestToken={token}
              forceLabelsOnDocProvide={!!token || previewing}
            />
          </div>
        </div>
      </div>
      <div
        id="request-content"
        className="mx-auto max-w-[90%] w-full p-4 pt-1 flex flex-1 flex-col m-6 bg-white rounded-2xl"
      >
        <Outlet />
        <div className="shadow-customShort mx-auto mb-1 p-2 w-full relative flex items-center justify-center rounded-t">
          {!readOnly && <RequestStatusIcon request={request} />}
          <h2 className="text-center text-xl text-secondary text-bold">{request.name}</h2>
        </div>
        {/* TODO: Fix overflow-x here, so whole editor can scroll */}
        <div className="shadow-custom mx-auto w-full rounded-b">
          {/* TODO: Does each bit of data need to be memoized to prevent the Editor re-renders? */}
          {/* TODO: Should force re-render on company switch */}
          <FormEditor
            canRefreshData={canRefreshData}
            setCanRefreshData={setCanRefreshData}
            data={editorData}
            disabled={isClosed || !!request.deletedAt}
            editorblock={'editorjs'}
            editorRef={editorRef}
            onCreateTemplate={onCreateBlockTemplate}
            onReady={() => {
              setIsReady(true);
            }}
            onSave={onSave}
            openFirstSection={!!token}
            previewing={previewing}
            quickCreateTitle="add section"
            readOnly={readOnly || isClosed || false}
            requestId={request._id}
            requestSent={isSent}
            type="base"
            token={token}
          />
        </div>
      </div>
      {!!template && (
        <RIDialog open={!!template} setOpen={(o) => setTemplate((v) => (o ? v : null))}>
          <CreateTemplateContainer close={() => setTemplate(null)} {...template} />
        </RIDialog>
      )}
      {!!editRequest && (
        <RIDialog open={!!editRequest} setOpen={setEditRequest}>
          <EditRequestContainer close={() => setEditRequest(false)} request={request} />
        </RIDialog>
      )}
      {!!showConversation && (
        <RIDialog open={!!showConversation} setOpen={setShowConversation}>
          <SendRequestMessageContainer
            close={() => {
              setPreppingSendRequest(false);
              setShowConversation(false);
            }}
            request={request}
            token={token}
            forceSendAll={preppingSendRequest}
            onSubmit={
              preppingSendRequest
                ? ({ message, notify = [REQUEST_NOTIFY_OPTION.ALL], replyTo, subject }) =>
                    sendRequest({ _id: request._id, customMessage: message, replyTo, notify, subject })
                : undefined
            }
          />
        </RIDialog>
      )}
    </div>
  );
};
