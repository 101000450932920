import { BlockToolConstructorOptions } from '@editorjs/editorjs/types/tools';
import { LocalTitleBlockTool } from '../_core/local-title-block/local-title-block.component';
import { ICreateTemplateBlockConfig } from '../_core/create-template-block';
import { IRenderOpenToggleParams, renderOpenToggle, renderWithBlockFocusWrapper } from '../_core/utils/editor.utils';
import { Section } from '../section';
import { ISectionData, SectionType } from '../section/section.types';
import { localTitleBlockExport, localTitleBlockImport } from '../_core/utils/block.utils';
import { ILabelBlockData } from '../_core/label-block';
import { createAnswerInput } from '../_core/utils/question.utils';
import { QuestionType } from '../question/question.types';
import { IBranchData } from '../branch';
import { getSectionElementIds, renderSectionTemplateHeader } from '../_core/utils/section.utils';
import { BlockTitles, BlockType } from '../_core/editor.const';

export interface IRepeatSectionData extends ILabelBlockData {
  repeatCount: number;
  sectionsData: ISectionData[];
  sectionTemplate: ISectionData;
}

export class RepeatSection extends LocalTitleBlockTool<IRepeatSectionData, ICreateTemplateBlockConfig> {
  private open: boolean[];
  private sections: Section[];

  private blockWrapperId: string;
  private repeatInputId: string;
  private repeatSectionIdPrefix: string;

  constructor(props: BlockToolConstructorOptions<IRepeatSectionData>) {
    const { data, ...rest } = props;
    super({
      ...rest,
      data: {
        ...data,
        repeatCount: data.repeatCount ?? 0,
        sectionsData: data.sectionsData ? [...data.sectionsData] : [],
        sectionTemplate: data.sectionTemplate ?? {
          type: SectionType.QUESTION_GROUP,
          outputData: { blocks: [] },
          completed: false,
          filled: false,
          required: false
        }
      }
    });

    this.blockWrapperId = 'block-wrapper-' + this.uid;
    this.repeatInputId = 'repeat-input-' + this.uid;
    this.repeatSectionIdPrefix = 'repeat-section-' + this.uid + '-';

    // Setup sections based on client mode, edit mode will only show the template section
    if (this.api.readOnly.isEnabled) {
      this.sections = [];
      for (let i = 0; i < this.data.repeatCount; i++) {
        this.sections.push(
          new Section({
            ...rest,
            data: this.data.sectionsData.length > i ? this.data.sectionsData[i] : this.data.sectionTemplate
          })
        );
      }
      this.open = this.sections.map(() => true);
    } else {
      this.sections = [new Section({ ...rest, data: this.data.sectionTemplate })];
      this.open = this.sections.map(() => true);
    }
  }

  static get enableLineBreaks() {
    return true;
  }

  static get isReadOnlySupported() {
    return true;
  }

  static get toolbox() {
    return {
      title: BlockTitles[BlockType.RepeatSection],
      icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M264.5 5.2c14.9-6.9 32.1-6.9 47 0l218.6 101c8.5 3.9 13.9 12.4 13.9 21.8s-5.4 17.9-13.9 21.8l-218.6 101c-14.9 6.9-32.1 6.9-47 0L45.9 149.8C37.4 145.8 32 137.3 32 128s5.4-17.9 13.9-21.8L264.5 5.2zM476.9 209.6l53.2 24.6c8.5 3.9 13.9 12.4 13.9 21.8s-5.4 17.9-13.9 21.8l-218.6 101c-14.9 6.9-32.1 6.9-47 0L45.9 277.8C37.4 273.8 32 265.3 32 256s5.4-17.9 13.9-21.8l53.2-24.6 152 70.2c23.4 10.8 50.4 10.8 73.8 0l152-70.2zm-152 198.2l152-70.2 53.2 24.6c8.5 3.9 13.9 12.4 13.9 21.8s-5.4 17.9-13.9 21.8l-218.6 101c-14.9 6.9-32.1 6.9-47 0L45.9 405.8C37.4 401.8 32 393.3 32 384s5.4-17.9 13.9-21.8l53.2-24.6 152 70.2c23.4 10.8 50.4 10.8 73.8 0z"/></svg>'
    };
  }

  private onOpenToggle({ index, renderParams }: { index: number; renderParams: IRenderOpenToggleParams }) {
    this.open[index] = !this.open[index];

    const ids = getSectionElementIds(this.repeatSectionIdPrefix, index);
    document.getElementById(ids.element)?.classList.toggle('hidden');
    renderOpenToggle({
      ...renderParams,
      open: this.open[index],
      replaceSelector: '#' + ids.titleWrapper,
      onOpenToggle: () => this.onOpenToggle({ index, renderParams })
    });
  }

  private renderSection({
    applyToDocumentImmediately,
    sectionIndex = 0
  }: {
    applyToDocumentImmediately?: boolean;
    sectionIndex?: number;
  }) {
    // Destroy old section editors, so new instances are created properly on demand
    if (applyToDocumentImmediately && sectionIndex < this.sections.length) {
      const replaceSection = this.sections[sectionIndex];
      replaceSection.editor.current?.destroy();
      replaceSection.editor.current = undefined;
    }

    // Create new section content and treat as always open
    const sectionElement = this.sections[sectionIndex].renderSectionContent();

    const sectionElementWrapper = renderSectionTemplateHeader({
      elementWrapperClass: this.repeatSectionIdPrefix,
      forceShowHeader: true,
      idPrefix: this.repeatSectionIdPrefix,
      onOpenToggle: (p) => this.onOpenToggle(p),
      open: this.open[sectionIndex],
      readOnly: this.api.readOnly.isEnabled,
      sectionElement,
      sectionIndex,
      sectionTitleText: this.api.readOnly.isEnabled ? `${sectionIndex + 1}` : 'Question Group Multi - Template'
    });

    // Return the section element unless it should be immediately inserted into the document (ie. on section re-render)
    if (applyToDocumentImmediately) {
      const blockWrapper = document.getElementById(this.blockWrapperId);
      blockWrapper?.appendChild(sectionElementWrapper);
    } else return sectionElementWrapper;
  }

  render() {
    // Setup block wrapper with label line, and section content beneath
    this.renderWithLocalTitle(this.api.readOnly ? undefined : { maxWidth: null, readOnly: { elementType: 'span' } });

    if (!this.wrapper) this.wrapper = document.createElement('div');
    this.wrapper.classList.add('flex', 'gap-4', 'rounded');

    const blockWrapper = document.createElement('div');
    blockWrapper.id = this.blockWrapperId;

    const configContainer = document.createElement('div');
    configContainer.classList.add('flex', 'gap-4', 'mt-2', 'w-full');

    // Add repeat input
    if (this.api.readOnly.isEnabled) {
      const answerContainer = createAnswerInput({
        id: this.repeatInputId,
        onAnswerChange: (t) => {
          const prevRepeatCount = this.data.repeatCount;
          const value = (t as HTMLInputElement).valueAsNumber;
          this.data.repeatCount = Math.max(0, Math.min(Math.floor(value), 10));

          // Update filled status
          this.data.filled = !!this.data.repeatCount;
          this.toggleRequiredIndicator({ checked: this.data.filled });

          if (this.data.repeatCount > prevRepeatCount) {
            // Push new copies of template section immediately to the DOM
            for (let i = prevRepeatCount; i < this.data.repeatCount; i++) {
              if (this.sections.length <= i) {
                this.sections.push(
                  new Section({
                    data: { ...this.data.sectionTemplate },
                    api: this.api,
                    block: this.block,
                    config: this.config,
                    readOnly: this.api.readOnly.isEnabled
                  })
                );
                this.data.sectionsData.push({ ...this.data.sectionTemplate });
                this.open.push(true);
              } else this.open[i] = true;
              this.renderSection({ applyToDocumentImmediately: true, sectionIndex: i });
            }
          } else if (this.data.repeatCount < prevRepeatCount) {
            // Remove elements from DOM immediately
            const elementsToRemove = prevRepeatCount - this.data.repeatCount;
            const currSections = document.querySelectorAll(`#${this.blockWrapperId} > .${this.repeatSectionIdPrefix}`);

            if (currSections && currSections?.length >= elementsToRemove) {
              const currSectionsList = [...currSections];
              for (let i = currSections.length - 1; i >= currSections.length - elementsToRemove; i--) {
                currSectionsList[i].remove();
              }
            }
          }
        },
        options: { [QuestionType.NUMBER]: { min: 0, max: 10, step: 1 } },
        placeholder: '0',
        prefix: 'num',
        readOnly: true,
        type: QuestionType.NUMBER,
        value: this.data.repeatCount.toString(),
        width: 'w-48'
      });

      answerContainer.querySelector('.answer-input')?.classList.add('rounded-l-none');

      // Add required UX components, if element is required
      const indicator = this.renderRequiredIndicator();
      if (indicator) answerContainer.appendChild(indicator);

      const completedCheckbox = this.renderCompletedCheckbox();
      if (completedCheckbox) answerContainer.appendChild(completedCheckbox);

      this.wrapper.appendChild(answerContainer);
    }

    // Add elements in block order
    blockWrapper.appendChild(this.wrapper);
    blockWrapper.appendChild(configContainer);

    // Render sections
    const sectionContent: (string | Node)[] = [];
    for (let i = 0; i < this.sections.length; i++) {
      const section = this.renderSection({ sectionIndex: i });
      if (section) sectionContent.push(section);
    }

    blockWrapper.append(...sectionContent);

    return renderWithBlockFocusWrapper(blockWrapper);
  }

  // Iterate through each block in the template and make sure the section data matches the template block format
  private updateChildBlocks(childData: ISectionData, templateData: ISectionData) {
    const newChildData: ISectionData = { ...childData, outputData: { ...childData.outputData, blocks: [] } };

    for (let templateIndex = 0; templateIndex < templateData.outputData.blocks.length; templateIndex++) {
      // Find the child block matching the template block by ID and type
      const templateBlock = templateData.outputData.blocks[templateIndex];
      const foundBlock = childData.outputData.blocks.find(
        (cb) => cb.id === templateBlock.id && cb.type === templateBlock.type
      );
      const childBlock = foundBlock ? { ...foundBlock, data: { ...foundBlock.data } } : null;

      // If child block was found and is a block with nested sections in it, then recursively check for child block updates within those sections
      if (childBlock?.type) {
        // Update required status based on latest template status
        if ('required' in childBlock.data && 'required' in templateBlock.data)
          childBlock.data.required = templateBlock.data.required;

        if (childBlock.type === 'questionGroup') {
          const newChildBlockData = { ...this.updateChildBlocks(childBlock.data as ISectionData, templateBlock.data) };
          childBlock.data = newChildBlockData;
        }
        // For branches and repeat sections we must iterate through each nested section to update the saved data to match the scheme of the new template block
        else if (childBlock.type === 'branch') {
          const childBranchData = { ...(childBlock.data as IBranchData) };
          const templateBranchData = templateBlock.data as IBranchData;

          const newChildBranchSectionData: ISectionData[] = [];
          for (
            let branchSectionIndex = 0;
            branchSectionIndex < childBranchData.sectionsData.length;
            branchSectionIndex++
          ) {
            newChildBranchSectionData.push(
              this.updateChildBlocks(
                childBranchData.sectionsData[branchSectionIndex],
                templateBranchData.sectionsData[branchSectionIndex]
              )
            );
          }

          childBranchData.sectionsData = newChildBranchSectionData;
          childBlock.data = childBranchData;
        } else if (childBlock.type === 'repeatSection') {
          const childRepeatSectionData = { ...(childBlock.data as IRepeatSectionData) };
          const templateRepeatSectionData = templateBlock.data as IRepeatSectionData;

          const newChildRepeatSectionData: ISectionData[] = [];
          for (
            let repeatSectionIndex = 0;
            repeatSectionIndex < childRepeatSectionData.sectionsData.length;
            repeatSectionIndex++
          ) {
            newChildRepeatSectionData.push(
              this.updateChildBlocks(
                childRepeatSectionData.sectionsData[repeatSectionIndex],
                templateRepeatSectionData.sectionsData[repeatSectionIndex]
              )
            );
          }

          childRepeatSectionData.sectionsData = newChildRepeatSectionData;
          childBlock.data = childRepeatSectionData;
        }
      }

      // Update child data with updated child block, or with new block from template
      newChildData.outputData.blocks.push(childBlock ?? { ...templateBlock });
    }

    return newChildData;
  }

  async save(blockContent: HTMLDivElement): Promise<IRepeatSectionData> {
    // Save data
    const newData = { ...(await super.save(blockContent)) };

    // Update appropriate section data depending on whether editing template in edit mode, or actual section answers in client mode
    if (this.api.readOnly.isEnabled)
      newData.sectionsData = await Promise.all(this.sections.map((s) => s.save(blockContent)));
    else if (this.sections.length) newData.sectionTemplate = await this.sections[0].save(blockContent);
    else throw Error('Failed to save Question Group Multi');

    if (!this.api.readOnly.isEnabled) {
      const newSectionsData: ISectionData[] = [];
      for (let i = 0; i < newData.sectionsData.length; i++) {
        newSectionsData.push(this.updateChildBlocks(newData.sectionsData[i], newData.sectionTemplate));
      }
      newData.sectionsData = newSectionsData;
    }

    // Update local data and data for each section instance
    this.data = newData;

    if (this.api.readOnly.isEnabled) this.sections.forEach((s, i) => s.updateData(this.data.sectionsData[i]));
    else if (this.sections.length) this.sections[0].updateData(this.data.sectionTemplate);
    else throw Error('Failed to save Question Group Multi');

    return newData;
  }

  static get conversionConfig() {
    return { export: localTitleBlockExport, import: localTitleBlockImport };
  }

  static get sanitize() {
    // Allo section data items to control their own HTML rules
    return { ...super.sanitize, sectionsData: true, sectionTemplate: true };
  }
}
