import { logError, sleep } from '../../../../lib/utils';
import { ICloseableDialogProps } from '../../../_core/core.types';
import { resetFormMessages } from '../../../_core/form/form.helpers';
import { IFormWithStepperProps } from '../../../_core/form/form.types';
import { Loader } from '../../../_core/loader.component';
import {
  useCompanyRequestTypes,
  useCreateRequestTypes,
  useDeleteRequestTypes,
  useRestoreRequestType,
  useSelectedRequestTypes
} from '../../request-type/request-type.service';
import { useCurrCompany, useEditCompanyRequestTypes } from '../company.service';
import { IEditRequestTypesProps } from '../company.types';
import { EditRequestTypesForm } from './edit-request-types-form.component';

export const EditRequestTypesNestedContainer = ({
  close,
  onError,
  onSuccess,
  ...rest
}: IFormWithStepperProps & ICloseableDialogProps) => {
  const { company } = useCurrCompany();
  const { companyRequestTypes } = useCompanyRequestTypes();
  const { requestTypes } = useSelectedRequestTypes();
  const { createRequestTypes } = useCreateRequestTypes();
  const { deleteRequestTypes } = useDeleteRequestTypes();
  const { editRequestTypes } = useEditCompanyRequestTypes();
  const { restoreRequestType } = useRestoreRequestType();

  const handleSubmit = async (props: IEditRequestTypesProps) => {
    await resetFormMessages({ setSubmitError: onError, setSuccess: onSuccess });

    const deletedTypes: string[] = [];
    const restoredCompanyTypes: string[] = [];
    const requestTypeIDs: string[] = [];
    const newTypes: string[] = [];

    // Separate new types from previously existing
    props.requestTypes.forEach(({ __isNew__, value }) => {
      if (__isNew__) newTypes.push(value);
      else requestTypeIDs.push(value);
    });

    // Determine which company-owned pre-existing types are being selected (which determines whether to delete previous actives, or restore previous inactives)
    companyRequestTypes?.forEach(({ _id, deletedAt }) => {
      const selectedRequestType = requestTypeIDs?.find((id) => _id === id);
      if (!deletedAt && !selectedRequestType) deletedTypes.push(_id);
      if (deletedAt && selectedRequestType) restoredCompanyTypes.push(_id);
    });

    let errored = false;

    try {
      if (newTypes.length) await createRequestTypes(newTypes);
    } catch (err) {
      errored = true;
      const errMsg = 'Unable to create new request types.';
      logError(errMsg, (err as Error).message);
      onError?.(errMsg);
    }

    try {
      if (restoredCompanyTypes.length)
        await Promise.all(restoredCompanyTypes.map((_id) => restoreRequestType({ _id })));
    } catch (err) {
      errored = true;
      const errMsg = 'Unable to reactivate request types.';
      logError(errMsg, (err as Error).message);
      onError?.(errMsg);
    }

    try {
      if (deletedTypes.length) await deleteRequestTypes(deletedTypes);
    } catch (err) {
      errored = true;
      const errMsg = 'Unable to unselect request types.';
      logError(errMsg, (err as Error).message);
      onError?.(errMsg);
    }

    try {
      await editRequestTypes(requestTypeIDs);
    } catch (err) {
      errored = true;
      const errMsg = 'Unable to set selected request types.';
      logError(errMsg, (err as Error).message);
      onError?.(errMsg);
    }

    if (!errored) {
      onSuccess?.('Successfully edited request types');
      await sleep(250);
      close();
    }
  };

  if (!requestTypes || !companyRequestTypes || !company) return <Loader />;
  return (
    <EditRequestTypesForm
      requestTypes={[...requestTypes.filter(({ company }) => !company), ...companyRequestTypes]}
      selectedRequestTypeIds={requestTypes.map(({ _id }) => _id)}
      onSubmit={handleSubmit}
      {...rest}
    />
  );
};
