import { BlockToolConstructorOptions } from '@editorjs/editorjs/types/tools';
import { LocalTitleBlockTool } from '../_core/local-title-block/local-title-block.component';
import { ICreateTemplateBlockConfig } from '../_core/create-template-block';
import { renderWithBlockFocusWrapper } from '../_core/utils/editor.utils';
import { localTitleBlockExport, localTitleBlockImport } from '../_core/utils/block.utils';
import { ILabelBlockData } from '../_core/label-block';
import { BlockTitles, BlockType } from '../_core/editor.const';

interface CheckboxData extends ILabelBlockData {
  checked: boolean;
}

export class Checkbox extends LocalTitleBlockTool<CheckboxData, ICreateTemplateBlockConfig> {
  constructor(props: BlockToolConstructorOptions<CheckboxData>) {
    super(props);
  }

  static get toolbox() {
    return {
      title: BlockTitles[BlockType.Checkbox],
      icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="m4.5 12.75 6 6 9-13.5" /></svg>'
    };
  }

  render() {
    super.render();
    if (!this.wrapper) this.wrapper = document.createElement('div');
    this.wrapper.classList.add('flex', 'gap-4', 'w-full', 'items-center');

    // Render checkbox
    const checkbox = document.createElement('input');
    checkbox.type = 'checkbox';
    checkbox.classList.add('cursor-pointer', 'disabled:text-gray-400', 'disabled:cursor-default');
    checkbox.onchange = (e) => this.onAnswerChange(e);
    checkbox.checked = this.data.checked;
    checkbox.disabled = this.config.disabled;
    this.wrapper.appendChild(checkbox);

    // Add required UX components, if element is required
    const indicator = this.renderRequiredIndicator();
    if (indicator) this.wrapper.appendChild(indicator);

    const completedCheckbox = this.renderCompletedCheckbox();
    if (completedCheckbox) this.wrapper.appendChild(completedCheckbox);

    return renderWithBlockFocusWrapper(this.renderWithLocalTitle());
  }

  private onAnswerChange({ target }: Event) {
    const targetE = target as HTMLInputElement;
    this.data.checked = targetE.checked;
    this.data.filled = this.data.checked;
    this.toggleRequiredIndicator({ checked: this.data.filled });
  }

  static get conversionConfig() {
    return { export: localTitleBlockExport, import: localTitleBlockImport };
  }
}
