import { API } from '@editorjs/editorjs';
import { BlockTool, BlockToolConstructorOptions, BlockToolData } from '@editorjs/editorjs/types/tools';
import { uniqueId } from 'lodash';
import { renderBlockConfigOutline } from './utils/block.utils';
import { SCROLL_INTO_VIEW_CLASS } from './editor.const';

export class BaseBlockTool<T extends BlockToolData> implements BlockTool {
  protected api: API;
  protected uid: string;
  protected toolbarWrapperId: string;
  protected wrapper?: HTMLDivElement;
  protected wrapperId: string;

  static get isReadOnlySupported(): boolean {
    return true;
  }

  static get sanitize(): Record<string, any> {
    return {};
  }

  constructor(props: BlockToolConstructorOptions) {
    this.api = props.api;
    this.uid = uniqueId();
    this.toolbarWrapperId = 'toolbar-indicator-wrapper-' + this.uid;
    this.wrapperId = 'wrapper-' + this.uid;
  }

  render(): HTMLElement {
    if (!this.wrapper)
      this.wrapper = renderBlockConfigOutline({
        onToggleToolbox: () => {
          // Scroll to parent (eg. section, if we have scrolled past the top of the section and the header is now stickied)
          const scrollToParent = document.getElementById(this.wrapperId)?.parentElement;
          if (scrollToParent?.classList.contains(SCROLL_INTO_VIEW_CLASS))
            scrollToParent.scrollIntoView({ behavior: 'smooth' });

          this.api.toolbar.toggleToolbox();
        },
        readOnly: this.api.readOnly.isEnabled,
        toolbarWrapperId: this.toolbarWrapperId,
        wrapperId: this.wrapperId
      });

    return this.wrapper;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async save(_blockContent: HTMLElement): Promise<T> {
    throw new Error('Unimplemented method');
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  validate(_blockData: T): boolean {
    throw new Error('Unimplemented method');
  }
}
