import { useState } from 'react';
import { Button, IButtonProps } from '../button/button.component';
import { ConfirmModal, IConfirmModalDetailsProps } from './confirm-modal.component';

interface IConfirmProps
  extends IConfirmModalDetailsProps,
    Pick<IButtonProps, 'hideEndMargin' | 'icon' | 'loading' | 'size' | 'variant'> {
  buttonTitle: string;
  skipConfirm?: boolean;
}

export const ConfirmButton = ({
  buttonTitle,
  icon,
  hideEndMargin,
  loading,
  size,
  skipConfirm,
  variant,
  ...modalProps
}: IConfirmProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Button
        text={buttonTitle}
        onClick={() => (skipConfirm ? modalProps.onConfirm() : setIsOpen(true))}
        loading={loading}
        size={size}
        hideEndMargin={hideEndMargin}
        icon={icon}
        variant={variant}
      />
      <ConfirmModal {...modalProps} open={isOpen} onClose={closeModal} />
    </>
  );
};
