import { IClientOverview, IEntity, IEntityMember } from '../../../../lib/types';
import { logError, sleep } from '../../../../lib/utils';
import { ICloseableDialogProps, IPersonalCompanyProps } from '../../../_core/core.types';
import { resetFormMessages } from '../../../_core/form/form.helpers';
import { IFormWithStepperProps } from '../../../_core/form/form.types';
import { useSendClientInvite } from '../../client-invite/client-invite.service';
import { ISendClientInvitesProps } from '../invite.types';
import { SendClientInvitesForm } from './send-client-invites-form.component';

export interface ISendClientInvitesContainerProps extends IPersonalCompanyProps, ICloseableDialogProps {
  isClientInvite?: boolean;
  clients: IClientOverview[];
  entity?: IEntity;
}

interface ISendClientInvitesNestedContainerProps extends IFormWithStepperProps, ISendClientInvitesContainerProps {
  entityMembers?: IEntityMember[];
}

export const SendClientInvitesNestedContainer = ({
  clients,
  close,
  entity,
  entityMembers,
  onError,
  onSuccess,
  ...rest
}: ISendClientInvitesNestedContainerProps) => {
  const { sendClientInvite } = useSendClientInvite();

  const handleSubmit = async ({ invites }: ISendClientInvitesProps) => {
    try {
      await resetFormMessages({ setSubmitError: onError, setSuccess: onSuccess });

      await Promise.all(
        invites.map(
          ({ value }) =>
            new Promise((res) => {
              const match = clients.find((c) => c._id === value);
              if (match)
                res(
                  sendClientInvite({
                    email: match.user.email,
                    name: match.name ?? match.user.name,
                    entityId: entity?._id
                  })
                );
              else res(null);
            })
        )
      );
      onSuccess?.('Successfully added contacts');
      sleep(250).then(close);
    } catch (err) {
      const errMsg = 'Unable to add contacts.';
      logError(errMsg, (err as Error).message);
      onError?.(errMsg);
    }

    return;
  };

  return (
    <SendClientInvitesForm
      clients={clients.filter((c) => !entityMembers?.find((em) => em.user._id === c.user._id))}
      onSubmit={handleSubmit}
      hasEntity={!!entity}
      {...rest}
    />
  );
};
