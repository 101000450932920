import { FormContainer } from '../../../_core/form/form-container.component';
import { useRef } from 'react';
import { useTemplate } from '../template.service';
import { FormEditor } from '../../../_pages/FormBuilderPage/form-editor.component';
import { Loader } from '../../../_core/loader.component';
import EditorJS, { OutputData } from '@editorjs/editorjs';
import { HighlightText, LowlightText } from '../../../_core/typography';

export interface IPreviewTemplateContainerProps {
  templateId: string;
}

export const PreviewTemplateContainer = ({ templateId }: IPreviewTemplateContainerProps) => {
  const ref = useRef<EditorJS>();

  const { template } = useTemplate(templateId);

  return (
    <FormContainer title="Template Preview">
      {!template ? (
        <Loader />
      ) : (
        <>
          <div className="flex flex-col w-full items-center">
            <HighlightText>{template.title}</HighlightText>
            {!!template.description && <LowlightText>{template.description}</LowlightText>}
          </div>
          <FormEditor
            data={template as OutputData}
            editorblock={'preview-template-editorjs'}
            editorRef={ref}
            disabled
            onSave={async () => ({})}
            readOnly
            requestId=""
          />
        </>
      )}
    </FormContainer>
  );
};
