import { useMutation, useQuery } from '@apollo/client';
import { useCallback } from 'react';
import { ICompanyLocationsResult, ICreateLocationsProps, IUpdateLocationProps } from './location.types';
import {
  CREATE_LOCATIONS,
  DELETE_LOCATION,
  GET_COMPANY_LOCATIONS,
  RESTORE_LOCATION,
  UPDATE_LOCATION
} from './location.queries';
import { ILocation, Identifiable } from '../../../lib/types';
import { updateLocationInCache } from './location.utils';
import { IArchivableProps } from '../../../lib/query.types';
import { mergeCacheLists } from '../../../lib/utils';

export const useCompanyLocations = () => {
  const { data, ...rest } = useQuery<ICompanyLocationsResult, IArchivableProps>(GET_COMPANY_LOCATIONS, {
    fetchPolicy: 'cache-and-network',
    variables: { includeArchived: true }
  });

  return { ...rest, ...data };
};

export const useCreateLocations = () => {
  const [mutation, rest] = useMutation<{ createLocations: ILocation[] }>(CREATE_LOCATIONS, {
    context: { serializationKey: 'MUTATION', tracked: true },
    update: (cache, { data }) => {
      if (data) {
        // Update curr company locations
        const currCompanyLocations = cache.readQuery<ICompanyLocationsResult, IArchivableProps>({
          query: GET_COMPANY_LOCATIONS,
          variables: { includeArchived: true }
        })?.locations;

        cache.writeQuery<ICompanyLocationsResult, IArchivableProps>({
          query: GET_COMPANY_LOCATIONS,
          data: { locations: mergeCacheLists(currCompanyLocations ?? [], data.createLocations) },
          variables: { includeArchived: true }
        });
      }
    }
  });

  const createLocations = useCallback((variables: ICreateLocationsProps) => mutation({ variables }), [mutation]);

  return { createLocations, ...rest };
};

export const useDeleteLocation = () => {
  const [mutation, rest] = useMutation<{ deleteLocation: ILocation }>(DELETE_LOCATION, {
    context: { serializationKey: 'MUTATION', tracked: true },
    update: (cache, { data }) => {
      if (data?.deleteLocation) updateLocationInCache({ cache, location: data.deleteLocation });
    }
  });

  const deleteLocation = useCallback((variables: Identifiable) => mutation({ variables }), [mutation]);

  return { deleteLocation, ...rest };
};

export const useRestoreLocation = () => {
  const [mutation, rest] = useMutation<{ restoreLocation: ILocation }>(RESTORE_LOCATION, {
    context: { serializationKey: 'MUTATION', tracked: true },
    update: (cache, { data }) => {
      if (data?.restoreLocation) updateLocationInCache({ cache, location: data.restoreLocation });
    }
  });

  const restoreLocation = useCallback((variables: Identifiable) => mutation({ variables }), [mutation]);

  return { restoreLocation, ...rest };
};

export const useUpdateLocation = () => {
  const [mutation, rest] = useMutation<{ updateLocation: ILocation }>(UPDATE_LOCATION, {
    context: { serializationKey: 'MUTATION', tracked: true },
    update: (cache, { data }) => {
      if (data?.updateLocation) updateLocationInCache({ cache, location: data.updateLocation });
    }
  });

  const updateLocation = useCallback((variables: IUpdateLocationProps) => mutation({ variables }), [mutation]);

  return { updateLocation, ...rest };
};
