import { LandingPageContainer } from '../_pages/LandingPage/landing-page.container';
import { DefaultPageLayout } from './default-page.layout';

export const LandingPageLayout = () => {
  return (
    <DefaultPageLayout unauthenticatedMode fullSizeLogo className="gradient">
      <LandingPageContainer />
    </DefaultPageLayout>
  );
};
