import Header from '@editorjs/header';
import { IBlockConversionData } from '../_core/types';
import { API, BlockToolConstructorOptions } from '@editorjs/editorjs';
import { uniqueId } from 'lodash';
import { renderBlockConfigOutline } from '../_core/utils/block.utils';

export class RIHeader extends Header {
  private api: API;
  private uid: string;
  private toolbarWrapperId: string;
  private wrapper?: HTMLDivElement;
  private wrapperId: string;

  constructor(props: BlockToolConstructorOptions) {
    super(props);
    this.api = props.api;
    this.uid = uniqueId();
    this.toolbarWrapperId = 'toolbar-indicator-wrapper-' + this.uid;
    this.wrapperId = 'wrapper-' + this.uid;
  }

  render() {
    if (!this.wrapper)
      this.wrapper = renderBlockConfigOutline({
        onToggleToolbox: this.api.toolbar.toggleToolbox,
        readOnly: this.api.readOnly.isEnabled,
        toolbarWrapperId: this.toolbarWrapperId,
        wrapperId: this.wrapperId
      });

    this.wrapper.prepend(super.render());
    this.wrapper.classList.add('header-wrapper');

    return this.wrapper;
  }

  /**
   * Enable Conversion Toolbar. Paragraph can be converted to/from other tools
   */
  static get conversionConfig() {
    return {
      export: ({ text = '' }: { text?: string }) => {
        return JSON.stringify({ label: text } as IBlockConversionData);
      },
      import: (importString: string) => {
        const { label } = JSON.parse(importString) as IBlockConversionData;
        return { text: label };
      }
    };
  }
}
