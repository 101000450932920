import { PropsWithChildren } from 'react';

interface IStatusIcon extends PropsWithChildren {
  backgroundColor?: string;
  inline?: boolean;
  slim?: boolean;
}

export const StatusIcon = ({ backgroundColor, children, inline, slim }: IStatusIcon) => (
  <div
    className={`text-xs text-center text-white uppercase rounded-md font-medium ${slim ? 'py-1' : 'py-1.5'} ${
      inline ? 'px-2' : 'absolute left-2 px-4'
    } ${backgroundColor ?? 'bg-secondary'}`}
  >
    {children}
  </div>
);
