import { BlockToolConstructorOptions } from '@editorjs/editorjs/types/tools';
import { BlockTitles, BlockType } from '../_core/editor.const';
import { LocalTitleBlockTool } from '../_core/local-title-block/local-title-block.component';
import { createRoot } from 'react-dom/client';
import { renderWithBlockFocusWrapper } from '../_core/utils/editor.utils';
import { LegacyUploadData, UploadData, UploadFilesContainerHandle, UploadItemData, UploadMode } from './upload.types';
import { ICreateTemplateBlockConfig } from '../_core/create-template-block';
import { IRequestTokenParams } from '../../../lib/types';
import _, { uniqueId } from 'lodash';
import { UploadFilesContainer } from './upload-files-container';
import { createRef } from 'react';
import { sleep } from '../../../lib/utils';
import { UploadInput } from './upload-input';
import { IBlockConversionData } from '../_core/types';

export interface IUploadBlockConfig extends ICreateTemplateBlockConfig, IRequestTokenParams {
  mode?: UploadMode;
}

export class Upload extends LocalTitleBlockTool<UploadData, IUploadBlockConfig> {
  private filesContainerRef = createRef<UploadFilesContainerHandle>();
  private uploadInputRef = createRef<HTMLInputElement | null>();

  private isProvide: boolean;
  private isListMode: boolean;
  private mode: UploadMode;

  private inlineUploadId: string;
  private rootUploadId: string;

  constructor(props: BlockToolConstructorOptions<LegacyUploadData>) {
    const { data, ...rest } = props;
    const newData = {
      ...data,
      label: data.label ?? 'Please upload the following document(s):',
      uploads: [
        ...(data.uploads ?? []).map((u) => ({
          ...u,
          fileContainerId: u.fileContainerId || u.fileId || new Date().getTime() + '-' + uniqueId(),
          fileName: _.unescape(u.fileName),
          label: _.unescape(u.label),
          notes: _.unescape(u.notes),
          clientAdded: u.clientAdded ?? true
        }))
      ]
    };

    super({ ...rest, data: newData });

    this.isProvide = rest.config.mode === 'provide';
    this.isListMode = rest.config.mode === 'upload-list';
    this.mode = this.config.mode ?? 'upload';

    this.inlineUploadId = 'inline-upload-' + this.uid;
    this.rootUploadId = 'root-upload-' + this.uid;
  }

  static get sanitize() {
    // disallow HTML
    return { ...super.sanitize, value: false };
  }

  static get toolbox() {
    return {
      title: BlockTitles[BlockType.DocUpload],
      icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="m18.375 12.739-7.693 7.693a4.5 4.5 0 0 1-6.364-6.364l10.94-10.94A3 3 0 1 1 19.5 7.372L8.552 18.32m.009-.01-.01.01m5.699-9.941-7.81 7.81a1.5 1.5 0 0 0 2.112 2.13" /></svg>'
    };
  }

  private toggleUploadInputs({ hideRoot }: { hideRoot: boolean }) {
    // Hide upload root and show inline file add button, or inverse
    document.getElementById(this.rootUploadId)?.classList.toggle('hidden', hideRoot);
    document.getElementById(this.inlineUploadId)?.classList.toggle('hidden', !hideRoot);
  }

  render() {
    this.renderWithLocalTitle({
      maxWidth: 'max-w-full',
      editOnly: {
        prefix: this.isProvide ? 'Provide' : this.isListMode ? 'UploadList' : 'Upload',
        prefixClass: 'min-w-20'
      },
      readOnly: { elementType: 'span' }
    });
    if (!this.wrapper) this.wrapper = document.createElement('div');
    this.wrapper.classList.add('flex', 'gap-4');
    this.wrapper.id = '';

    // Render content with settings and local title content prepened
    const topContainer = document.createElement('div');
    topContainer.classList.add('flex', 'gap-4');
    topContainer.id = this.wrapperId; // NOTE: Need to set wrapper ID to top container of DOM element so save attempts correctly find all child elements

    const container = document.createElement('div');
    container.classList.add('flex', 'flex-col', 'w-full');
    container.appendChild(this.wrapper);

    // Render upload input
    if (!this.config.disabled && !this.isProvide && !this.isListMode) {
      const uploadRoot = document.createElement('div');
      uploadRoot.id = this.rootUploadId;

      uploadRoot.classList.add('flex', 'items-center', 'justify-center', 'w-1/2');
      if (this.data.uploads.length) uploadRoot.classList.add('hidden');

      const root = createRoot(uploadRoot);
      root.render(
        <UploadInput
          disabled={this.config.disabled}
          multiple
          onSave={this.config.onSave}
          onUploaded={(f) => {
            // Add file to container
            this.filesContainerRef.current?.addFile(f);

            this.toggleUploadInputs({ hideRoot: true });
          }}
          size="xl"
          uid={this.uid}
          requestToken={this.config.requestToken}
          uploadRef={this.uploadInputRef}
        />
      );

      this.wrapper.appendChild(uploadRoot);
    }

    // Render upload files container and current uploads
    const filesContainer = document.createElement('div');
    filesContainer.id = 'files-container-' + this.uid;
    filesContainer.classList.add('flex', 'flex-col', 'gap-2');

    const filesContainerRoot = createRoot(filesContainer);
    filesContainerRoot.render(
      <UploadFilesContainer
        uploads={this.data.uploads}
        onSave={this.config.onSave}
        onUpdate={(uploads) => {
          this.data.uploads = uploads;

          // Update filled
          const isFilled = !!uploads.length;
          this.data.filled = isFilled;
          this.toggleRequiredIndicator({ checked: this.data.filled });

          if (!isFilled && this.mode === 'upload') this.toggleUploadInputs({ hideRoot: false });
        }}
        readOnly={this.api.readOnly.isEnabled}
        requestId={this.config.requestId}
        uid={this.uid}
        disabled={this.config.disabled}
        mode={this.mode}
        requestToken={this.config.requestToken}
        ref={this.filesContainerRef}
        inlineUploadId={this.inlineUploadId}
      />
    );

    container.appendChild(filesContainer);

    // Add required UX components, if element is required
    const indicator = this.renderRequiredIndicator();
    if (indicator) this.wrapper.appendChild(indicator);

    const completedCheckbox = this.renderCompletedCheckbox();
    if (completedCheckbox) this.wrapper.appendChild(completedCheckbox);

    topContainer.appendChild(container);
    return renderWithBlockFocusWrapper(topContainer);
  }

  async save(blockContent: HTMLDivElement): Promise<UploadData> {
    const newData = await super.save(blockContent);
    newData.uploads = this.data.uploads.filter((u) => u.fileId || u.fileName || u.label || u.notes);
    return newData;
  }

  // Keep trying to render local title with latest depth until container ref is populated
  private updateFilesContainerLocalTitleDepth() {
    if (!this.filesContainerRef.current) sleep(100).then(() => this.updateFilesContainerLocalTitleDepth());
    else this.filesContainerRef.current?.updateNestedDepth(this.nestedDepth);
  }

  rendered() {
    super.rendered();
    this.updateFilesContainerLocalTitleDepth();
  }

  /**
   * Called each time block content is updated. Used to update the files container with accurate nested depth after local titles finish rendering
   */
  updated() {
    super.updated();
    this.updateFilesContainerLocalTitleDepth();
  }

  static get conversionConfig() {
    return {
      export: (data: UploadData) => {
        const convertedData = { label: data.label, required: data.required, uploads: data.uploads };
        return JSON.stringify(convertedData);
      },
      import: (importString: string): Partial<UploadData> => {
        const importData = JSON.parse(importString) as { uploads?: UploadItemData[] } & IBlockConversionData;
        return { label: importData.label, required: importData.required ?? false, uploads: importData.uploads ?? [] };
      }
    };
  }
}
