import { Question } from '../../_editor/question';
import { Radio } from '../../_editor/radio';
import { Checkbox } from '../../_editor/checkbox';
import { YesNo } from '../../_editor/yes-no';
import { DocProvide, Upload, UploadList } from '../../_editor/upload';
import { ISelectTemplateConfig, SelectTemplate } from '../../_editor/select-template';
import { QuestionGroup, Section } from '../../_editor/section';
import { Branch } from '../../_editor/branch';
import { IEditorToolsConfig, SectionType } from '../../_editor/section/section.types';
import { ICreateTemplateBlockConfig } from '../../_editor/_core/create-template-block';
import { FormEditorType } from './form-editor.types';
import { TEMPLATE_TYPE } from '../../../lib/types';
import { RepeatSection } from '../../_editor/repeat-section';
import { RIHeader } from '../../_editor/header';
import { Link } from '../../_editor/link';
import { BlockTitles, BlockType } from '../../_editor/_core/editor.const';
import { RichText } from '../../_editor/text';

export const getEditorTools = (
  config: ICreateTemplateBlockConfig,
  type?: FormEditorType,
  requestToken?: string
): IEditorToolsConfig => {
  const baseTools: IEditorToolsConfig = {
    [BlockType.Text]: RichText
  };

  const mainTools: IEditorToolsConfig = {
    ...baseTools,
    [BlockType.Section]: {
      // @ts-expect-error Class typings not aligned
      class: Section,
      config: { ...config, sectionType: SectionType.SECTION, requestToken, disableConvert: true }
    }
  };

  // Redefine tool order
  const sectionTools: IEditorToolsConfig = {
    // TODO: Fix type errors with custom class API and config params
    // @ts-expect-error Class typings not aligned
    [BlockType.Question]: { class: Question, config },
    ...baseTools,
    [BlockType.Header]: {
      // @ts-expect-error Class typings not aligned
      class: RIHeader,
      shortcut: 'CMD+SHIFT+H',
      toolbox: { title: BlockTitles[BlockType.Header] }
    },
    // TODO: Line component
    [BlockType.QuestionGroup]: {
      // @ts-expect-error Class typings not aligned
      class: QuestionGroup,
      config: { ...config, sectionType: SectionType.QUESTION_GROUP, requestToken }
    },
    // @ts-expect-error Class typings not aligned
    [BlockType.RepeatSection]: { class: RepeatSection, config: { ...config, requestToken } },
    // @ts-expect-error Class typings not aligned
    [BlockType.Branch]: { class: Branch, config: { ...config, requestToken } },
    // @ts-expect-error Class typings not aligned
    [BlockType.DocUpload]: { class: Upload, config: { ...config, requestToken } },
    // @ts-expect-error Class typings not aligned
    [BlockType.DocUploadList]: { class: UploadList, config: { ...config, requestToken } },
    // @ts-expect-error Class typings not aligned
    [BlockType.DocProvide]: { class: DocProvide, config: { ...config, requestToken } },
    // TODO: Table component
    // @ts-expect-error Class typings not aligned
    [BlockType.YesNo]: { class: YesNo, config },
    // TODO: Dropdown component
    // @ts-expect-error Class typings not aligned
    [BlockType.Radio]: { class: Radio, config },
    // @ts-expect-error Class typings not aligned
    [BlockType.Checkbox]: { class: Checkbox, config },
    // @ts-expect-error Class typings not aligned
    [BlockType.Link]: { class: Link, config }
    // TODO: Video-provide component
  };

  if (type === 'base')
    return {
      ...baseTools,
      selectTemplate: {
        // @ts-expect-error Class typings not aligned
        class: SelectTemplate,
        config: { templateType: TEMPLATE_TYPE.SECTION } as ISelectTemplateConfig
      },
      [BlockType.Section]: mainTools['section'] // redefine order of tools
    };
  else if (type === 'section')
    return {
      ...sectionTools,
      selectTemplate: {
        // @ts-expect-error Class typings not aligned
        class: SelectTemplate,
        config: { templateType: TEMPLATE_TYPE.BLOCK } as ISelectTemplateConfig
      }
    };
  else return { ...sectionTools, ...mainTools };
};
