import { ClientProfilePage } from './client-profile-page.component';
import { Loader } from '../../_core/loader.component';
import { useNavigate, useParams } from 'react-router-dom';
import { useDeleteClient, useClient, useRestoreClient } from '../../domains/clients/client.service';
import { useCurrMember } from '../../domains/member/member.service';
import { useEntityMembers } from '../../domains/entity-members/entity-members.service';
import { useCurrCompany } from '../../domains/companies/company.service';
import { useEffect } from 'react';
import { ROUTE_PATHS } from '../../../_routes';
import { showError } from '../../../lib/utils';

export const ClientProfilePageContainer = () => {
  const navigate = useNavigate();
  const params = useParams();

  const { client } = useClient({ _id: params.clientId ?? '' });
  const { entityMembers } = useEntityMembers({ userId: client?.user._id });

  const { company, isPersonal } = useCurrCompany();
  const { isAdmin } = useCurrMember();
  const { deleteClient, loading: deleting } = useDeleteClient();
  const { restoreClient, loading: restoring } = useRestoreClient();

  useEffect(() => {
    if (client && company && client.company?._id !== company._id) navigate(ROUTE_PATHS.DASHBOARD);
  }, [company, client, navigate]);

  if (!client) return <Loader />;

  return (
    <div className="flex min-h-full flex-1 flex-col items-center">
      <ClientProfilePage
        deleteClient={async () => {
          try {
            await deleteClient(client);
          } catch (err) {
            showError('Unable to delete contact', err as Error);
          }
        }}
        deleting={deleting}
        client={client}
        isAdmin={isAdmin}
        isPersonalCompany={isPersonal}
        members={entityMembers}
        restoreClient={async () => {
          try {
            await restoreClient(client);
          } catch (err) {
            showError('Unable to restore contact', err as Error);
          }
        }}
        restoring={restoring}
      />
    </div>
  );
};
